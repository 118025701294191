import { clamp } from 'lodash'
import classNames from 'classnames'
import React, { memo, useState } from 'react'
import { GqlEvaluationCriteriaRatingScale } from '@gql'
import { AsyncInput, AsyncSelect, Text } from '@cotiss/common'
import { EVALUATION_EVENT_RATING_SCALE_DENOMINATOR_MAP, EVALUATION_EVENT_SCORE_VALUE_BY_RATING_SCALE_OPTIONS } from '@cotiss/evaluation-event'

type Props = {
  className?: string
  ratingScale: GqlEvaluationCriteriaRatingScale
  value?: string
  onSubmit: (value: string) => Promise<void>
  isDisabled?: boolean
}

export const EvaluationEventRatingScaleValueField = memo(({ className, ratingScale, value, onSubmit, isDisabled }: Props) => {
  const [stateValue, setStateValue] = useState(value || '')
  const classes = classNames(className, 'flex items-center w-full')

  const renderContent = () => {
    if (ratingScale === 'percentage') {
      return (
        <AsyncInput
          className="w-full"
          value={stateValue}
          onChange={({ target }) => setStateValue(target.value)}
          onSubmit={() => onSubmit(stateValue)}
          placeholder="--"
          isDisabled={isDisabled}
        />
      )
    }

    if (ratingScale === 'zeroToOneHundredWithStaggeredDefinitions') {
      return (
        <AsyncInput
          className="w-full"
          value={stateValue}
          onChange={({ target }) => setStateValue((clamp(Number(target.value), 0, 100) || 0).toString())}
          onSubmit={() => onSubmit(stateValue)}
          placeholder="--"
          min={0}
          max={100}
          step={1}
          isDisabled={isDisabled}
        />
      )
    }

    return (
      <AsyncSelect
        className="w-full"
        value={value}
        options={EVALUATION_EVENT_SCORE_VALUE_BY_RATING_SCALE_OPTIONS[ratingScale]}
        onSubmit={onSubmit}
        placeholder="--"
        isDisabled={isDisabled}
      />
    )
  }

  return (
    <div className={classes}>
      {renderContent()}
      <Text className="flex items-center shrink-0 w-10 ml-2">
        {ratingScale === 'percentage' ? '%' : `/ ${EVALUATION_EVENT_RATING_SCALE_DENOMINATOR_MAP[ratingScale]}`}
      </Text>
    </div>
  )
})
