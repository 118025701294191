import React, { memo, useState } from 'react'
import { useGetLoggedInUser, userService } from '@cotiss/user'
import { Button, routerService, Icon, Text, Avatar, Dropdown, DropdownContent } from '@cotiss/common'
import classNames from 'classnames'

type Props = {
  state?: 'simple' | 'full'
}

export const AppSideNavProfile = memo(({ state = 'full' }: Props) => {
  const { user } = useGetLoggedInUser()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <>
      <Button
        className={classNames('flex items-center justify-start rounded w-full py-1 hover:bg-primary-100', {
          'px-2': state === 'full',
          'justify-center': state === 'simple',
        })}
        onClick={() => setIsDropdownOpen(true)}
        state="raw"
      >
        <Avatar className={state === 'full' ? 'mr-2' : ''} size="sm" variant="secondary">
          {userService.getInitials(user)}
        </Avatar>
        {state === 'full' && <Text className="line-clamp-2">{userService.getFullName(user)}</Text>}
      </Button>
      <Dropdown className="left-[calc(100%+8px)] bottom-2 origin-bottom-left" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
        <DropdownContent>
          <div className="flex items-center border-b border-gray-100 pb-3">
            <Avatar className="mr-2" size="sm" variant="secondary">
              {userService.getInitials(user)}
            </Avatar>
            <div>
              <Text className="whitespace-pre-wrap max-w-[250px]">{userService.getFullName(user)}</Text>
              <Text size="sm" variant="light">
                {user?.email}
              </Text>
            </div>
          </div>
          <Button
            className="flex items-center text-left rounded px-2 py-1 mt-2 w-full hover:bg-primary-100"
            href={routerService.getHref('/logout')}
            state="raw"
            isLink
          >
            <Icon className="mr-2" icon="logout" />
            <Text>Logout</Text>
          </Button>
          <div className="mt-2">
            <Text variant="light" size="sm">
              Powered by <strong>Cotiss</strong>
            </Text>
          </div>
        </DropdownContent>
      </Dropdown>
    </>
  )
})
