import { clamp, map } from 'lodash'
import React, { memo, useState } from 'react'
import { UserAvatarGroup } from '@cotiss/user'
import { SettingsModulesApprovalTemplateCreateUpdateModal } from '@cotiss/settings'
import { GqlApprovalTemplateFieldsFragment, GqlApprovalTemplateModuleType } from '@gql'
import { mutateUpdateApprovalTemplate, useApprovalTemplate } from '@cotiss/approval-template'
import { AsyncInput, Button, ConfirmModal, Dropdown, DropdownContent, Icon, Text, sentryService, useCallout, useToast } from '@cotiss/common'

type Props = {
  approvalTemplate: GqlApprovalTemplateFieldsFragment
  module: GqlApprovalTemplateModuleType
  onUpdate: () => void | Promise<unknown>
  isEditable?: boolean
  isDisabled?: boolean
}

export const ApprovalTemplateGroupApprovalTemplateListItem = memo(({ approvalTemplate, module, onUpdate, isEditable, isDisabled }: Props) => {
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { mutateDeleteApprovalTemplate } = useApprovalTemplate()
  const [approvalSequenceOrder, setApprovalSequenceOrder] = useState(approvalTemplate.order || '1')

  const handleEditApprovalTemplate = () => {
    if (!approvalTemplate.approvalTemplateGroupId) {
      return
    }

    openModal(
      <SettingsModulesApprovalTemplateCreateUpdateModal
        approvalTemplateGroupId={approvalTemplate.approvalTemplateGroupId}
        approvalTemplate={approvalTemplate}
        module={module}
        onUpdate={onUpdate}
      />
    )
  }

  const handleDeleteApprovalTemplate = () => {
    openModal(
      <ConfirmModal
        heading="Delete approval type"
        description="Are you sure you want to delete this approval type?"
        onSubmit={async () => {
          await mutateDeleteApprovalTemplate({ approvalTemplateId: approvalTemplate.id })
          onUpdate && (await onUpdate())
        }}
      />
    )
  }

  const handleUpdateSequenceOrder = async () => {
    if (!approvalSequenceOrder || approvalSequenceOrder === approvalTemplate.order) {
      return
    }

    try {
      setIsSaving(true)

      const order = clamp(Number(approvalSequenceOrder), 1, 99)
      await mutateUpdateApprovalTemplate({
        approvalTemplateId: approvalTemplate.id,
        order,
        isOptional: approvalTemplate.isOptional,
      })

      setApprovalSequenceOrder(order)
      onUpdate && (await onUpdate())
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <div className="mt-2 group flex items-center">
      <div className="w-[50px] mr-4">
        <AsyncInput
          value={approvalSequenceOrder}
          onChange={({ target }) => setApprovalSequenceOrder(target.value)}
          onSubmit={handleUpdateSequenceOrder}
          type="number"
          placeholder="--"
          min={1}
          max={99}
          isDisabled={isSaving}
        />
      </div>
      <div className="flex items-center justify-between bg-gray-50 border border-gray-200 rounded-lg cursor-default w-full">
        <div className="relative flex items-center justify-between border-r border-gray-200 w-full p-4">
          <Text>{approvalTemplate.name}</Text>
          {isEditable && (
            <>
              <Button
                className="mr-2"
                onClick={() => setIsDropdownOpen(true)}
                shape="square"
                state="translucent"
                variant="secondary-dark"
                size="xs"
                isDisabled={isDisabled}
                isRounded
              >
                <Icon icon="dots" variant="secondary" />
              </Button>
              <Dropdown className="top-1/2 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
                <DropdownContent className="flex items-center" onClick={handleEditApprovalTemplate} isDisabled={isDisabled}>
                  Edit
                </DropdownContent>
                <DropdownContent className="flex items-center" onClick={handleDeleteApprovalTemplate} isDisabled={isDisabled}>
                  Delete
                </DropdownContent>
              </Dropdown>
            </>
          )}
        </div>
        <div className="flex items-center justify-start border-r border-gray-200 w-1/2 p-4">
          <Text>{approvalTemplate.isOptional ? 'Optional' : 'Mandatory'}</Text>
        </div>
        <div className="flex items-center justify-start w-1/2 p-4">
          <UserAvatarGroup size="sm" users={map(approvalTemplate.approvalTemplateUsers, 'user')} />
        </div>
      </div>
    </div>
  )
})
