import { SignUpSupplierFormData } from '@cotiss/auth'
import { Button, Input, Label, Select, TransitionContainer, TransitionType } from '@cotiss/common'
import { ORGANISATION_ENTITY_TYPE_OPTIONS } from '@cotiss/organisation'
import React, { memo } from 'react'

type Props = {
  formData: SignUpSupplierFormData
  transition: TransitionType
  setFormData: (formData: SignUpSupplierFormData) => void
  isDisabled?: boolean
}

export const SignUpSupplierStepCompanyDetails = memo(({ formData, transition, setFormData, isDisabled }: Props) => {
  return (
    <TransitionContainer transition={transition}>
      <Label>Tax number</Label>
      <Input
        value={formData.taxNumber}
        onChange={({ target }) => setFormData({ ...formData, taxNumber: target.value })}
        name="Tax number"
        placeholder="Enter tax number"
        maxLength={100}
        isDisabled={isDisabled}
        isRequired
      />
      <Label className="mt-6">Entity type</Label>
      <Select
        value={formData.entityType}
        options={ORGANISATION_ENTITY_TYPE_OPTIONS}
        onChange={(entityType) => setFormData({ ...formData, entityType })}
        placeholder
        isRequired
      />
      <Button className="mt-6 w-full" type="submit" isDisabled={isDisabled}>
        Next
      </Button>
    </TransitionContainer>
  )
})
