import { GqlCurrencyCode } from '@gql'
import { SelectOption } from '@cotiss/common'

export const CURRENCY_OPTIONS: SelectOption<GqlCurrencyCode>[] = [
  { value: 'NZD', label: 'NZD' },
  { value: 'AUD', label: 'AUD' },
  { value: 'USD', label: 'USD' },
  { value: 'GBP', label: 'GBP' },
  { value: 'EUR', label: 'EUR' },
]
