// ! These are also defined in ./tailwind.config.js. Make sure if you change these values, you also update them in that config file.

export const COLOUR = {
  transparent: 'transparent',
  white: '#FFFFFF',
  black: '#000000',
  light: '#888F9B',

  alerts: {
    error: {
      300: '#FF8074',
      400: '#F04438',
      500: '#D2261A',
    },
    success: {
      300: '#28D880',
      400: '#00B058',
      500: '#008830',
    },
  },

  primary: {
    50: '#F7F8FB',
    100: '#F3F4F6',
    200: '#E7E7ED',
    300: '#DBDBE4',
    400: '#9595B7',
    500: '#0F1048',
    600: '#0A0A37',
    700: '#1E1E3A',
    800: '#0B0C36',
    900: '#070720',
  },

  secondary: {
    50: '#FAF9FF',
    100: '#F2F1FF',
    200: '#E9E8FF',
    300: '#DBDAFF',
    400: '#BCB9FF',
    500: '#5B53E6',
    600: '#524BCF',
    700: '#443EAD',
    800: '#292567',
    900: '#201D51',
  },

  gray: {
    50: '#F9FAFB',
    100: '#F3F4F6',
    200: '#F0F1F3',
    300: '#D1D5DB',
    400: '#9CA3AF',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827',
  },

  orange: {
    200: '#FEF5E6',
    300: '#FEF1D9',
    400: '#FCE1B0',
    500: '#F69F00',
    600: '#DD8F00',
    700: '#C57F00',
    800: '#945F00',
    900: '#563800',
  },

  pink: {
    200: '#FFEBF4',
    300: '#FEE0EE',
    400: '#FEC0DC',
    500: '#FB338E',
    600: '#E22E80',
    700: '#BC266B',
    800: '#971F55',
    900: '#711740',
  },

  green: {
    200: '#E6F9EF',
    300: '#D9F6E7',
    400: '#B0ECCE',
    500: '#00C362',
    600: '#00B058',
    700: '#00924A',
    800: '#00753B',
    900: '#004422',
  },

  blue: {
    200: '#E6FAFA',
    300: '#D9F7F7',
    400: '#B0EFEF',
    500: '#00CBCB',
    600: '#00B7B7',
    700: '#00A2A2',
    800: '#007A7A',
    900: '#004747',
  },
} as const
