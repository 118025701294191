import { compact, find, map, orderBy } from 'lodash'
import React, { memo, useMemo } from 'react'
import { MultiSelect, SelectOption, Skeleton, Select } from '@cotiss/common'
import { PreferredSupplierPopulatedModel, useListPreferredSupplier } from '@cotiss/preferred-supplier'

type BaseProps = {
  className?: string
  isRequired?: boolean
  isDisabled?: boolean
  placeholder?: string | true
}

type ConditionalProps =
  | {
      value: PreferredSupplierPopulatedModel[]
      onChange: (value: PreferredSupplierPopulatedModel[]) => void
      isMulti: true
    }
  | {
      value?: PreferredSupplierPopulatedModel
      onChange: (value: PreferredSupplierPopulatedModel) => void
      isMulti?: false | undefined
    }

type Props = BaseProps & ConditionalProps

export const PreferredSupplierSelect = memo(({ className, value, onChange, placeholder, isMulti, isRequired, isDisabled }: Props) => {
  const { preferredSuppliers, isLoading } = useListPreferredSupplier({ pending: false })
  const supplierOptions: SelectOption[] = useMemo(() => {
    const supplierOptions = orderBy(
      map(preferredSuppliers, ({ _id, organisationName, supplierOrganisation }) => {
        // TODO: seems like we don't want to use supplierOrganisation here, but we have to because there's no organisationName
        const label = supplierOrganisation?.name || organisationName

        return label ? { value: _id, label } : null
      }),
      ['label'],
      ['asc']
    )

    return compact(supplierOptions)
  }, [preferredSuppliers])

  if (isLoading) {
    return <Skeleton className="h-10 w-70" />
  }

  return isMulti ? (
    <MultiSelect
      className={className}
      value={compact(map(value, (supplier) => find(supplierOptions, ({ value }) => value === supplier._id)))}
      options={supplierOptions}
      onChange={(option) => onChange(compact(map(option, ({ value }) => find(preferredSuppliers, { _id: value }))))}
      placeholder={placeholder}
      isRequired={isRequired}
      isDisabled={isDisabled}
    />
  ) : (
    <Select
      className={className}
      value={value?._id}
      options={supplierOptions}
      onChange={(_id) => onChange(find(preferredSuppliers, { _id }) as PreferredSupplierPopulatedModel)}
      placeholder={placeholder}
      isRequired={isRequired}
      isDisabled={isDisabled}
    />
  )
})
