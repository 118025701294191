import { SignUpSupplierFormData } from '@cotiss/auth'
import { Button, Input, Label, Select, TransitionContainer, TransitionType } from '@cotiss/common'
import {
  ORGANISATION_CANADA_SUBDIVISION_OPTIONS,
  ORGANISATION_COUNTRY_OPTIONS,
  ORGANISATION_US_SUBDIVISION_OPTIONS,
  organisationService,
} from '@cotiss/organisation'
import React, { memo } from 'react'

type Props = {
  formData: SignUpSupplierFormData
  transition: TransitionType
  setFormData: (formData: SignUpSupplierFormData) => void
  isDisabled?: boolean
  willProceedWithoutValidation?: boolean
}

export const SignUpSupplierStepCompanyDetailsExtended = memo(
  ({ formData, transition, setFormData, isDisabled, willProceedWithoutValidation }: Props) => {
    const processedCountryCode =
      formData.countryCode === 'CA' || formData.countryCode === 'US' ? formData.countryCodeSubdivision : formData.countryCode

    return (
      <TransitionContainer transition={transition}>
        <Label>Country</Label>
        <Select
          value={formData.countryCode}
          options={ORGANISATION_COUNTRY_OPTIONS}
          onChange={(countryCode) => setFormData({ ...formData, countryCode, countryCodeSubdivision: undefined })}
          placeholder
          isRequired
        />
        {(formData.countryCode === 'CA' || formData.countryCode === 'US') && (
          <>
            <Label className="mt-6">Region</Label>
            <Select
              value={formData.countryCodeSubdivision}
              options={formData.countryCode === 'CA' ? ORGANISATION_CANADA_SUBDIVISION_OPTIONS : ORGANISATION_US_SUBDIVISION_OPTIONS}
              onChange={(countryCodeSubdivision) => setFormData({ ...formData, countryCodeSubdivision })}
              placeholder
              isRequired
            />
          </>
        )}
        {processedCountryCode && (
          <>
            <Label className="mt-6">
              {organisationService.getRegistryNumberType(processedCountryCode)}
              {formData.entityType === 'Sole trader' ? ' (optional)' : ''}
            </Label>
            <Input
              value={formData.businessNumber}
              onChange={({ target }) => setFormData({ ...formData, businessNumber: target.value })}
              name={organisationService.getRegistryNumberType(processedCountryCode)}
              placeholder={`Enter ${organisationService.getRegistryNumberType(processedCountryCode)}`}
              maxLength={100}
              isDisabled={isDisabled}
              isRequired={formData.entityType !== 'Sole trader'}
            />
          </>
        )}
        <Button className="mt-6 w-full" type="submit" isDisabled={isDisabled}>
          {willProceedWithoutValidation ? 'My details are correct, proceed' : 'Next'}
        </Button>
      </TransitionContainer>
    )
  }
)
