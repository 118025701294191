import { find } from 'lodash'
import React, { memo } from 'react'
import { SignUpBuyerFormData } from '@cotiss/auth'
import {
  Button,
  COUNTRY_REGION_OPTIONS,
  FormHiddenInput,
  GROUPED_REGION_OPTIONS,
  Input,
  Label,
  Select,
  TransitionContainer,
  TransitionType,
} from '@cotiss/common'

type Props = {
  formData: SignUpBuyerFormData
  transition: TransitionType
  setFormData: (formData: SignUpBuyerFormData) => void
  isDisabled?: boolean
}

export const SignUpBuyerStepCompany = memo(({ formData, transition, setFormData, isDisabled }: Props) => (
  <TransitionContainer transition={transition}>
    <Label>Company name</Label>
    <Input
      value={formData.companyName}
      onChange={({ target }) => setFormData({ ...formData, companyName: target.value })}
      name="Company name"
      placeholder="Company name"
      isDisabled={isDisabled}
      isRequired
    />
    <Label className="mt-6">Country</Label>
    <Select
      value={formData.location.country}
      options={COUNTRY_REGION_OPTIONS}
      onChange={(country) => setFormData({ ...formData, location: { ...formData.location, country } })}
      isDisabled={isDisabled}
      placeholder
      isRequired
    />
    <Label className="mt-6">Address</Label>
    <Input
      value={formData.location.address}
      onChange={({ target }) => setFormData({ ...formData, location: { ...formData.location, address: target.value } })}
      placeholder="Address"
      isDisabled={isDisabled}
      isRequired
    />
    <Label className="mt-6">City</Label>
    <Input
      value={formData.location.city}
      onChange={({ target }) => setFormData({ ...formData, location: { ...formData.location, city: target.value } })}
      placeholder="City"
      isDisabled={isDisabled}
      isRequired
    />
    <div className="flex items-center justify-between mt-6">
      <div className="w-1/2 mr-4">
        <Label>Region</Label>
        <Select
          value={formData.location.region}
          options={find(GROUPED_REGION_OPTIONS, { label: formData.location.country })?.options || []}
          onChange={(region) => setFormData({ ...formData, location: { ...formData.location, region } })}
          placeholder="- Select region -"
          isDisabled={isDisabled}
          isRequired
        />
      </div>
      <div className="w-1/2">
        <Label>Zip/Postcode</Label>
        <Input
          value={formData.location.postcode}
          onChange={({ target }) => setFormData({ ...formData, location: { ...formData.location, postcode: target.value } })}
          placeholder="Zip/Postcode"
          isDisabled={isDisabled}
          isRequired
        />
      </div>
    </div>
    <Button className="mt-6 w-full" type="submit" isDisabled={isDisabled}>
      Next
    </Button>

    {/* Hidden for elements, so HubSpot data is captured, and linked to the user signing up */}
    <FormHiddenInput value={formData.email} type="email" />
    <FormHiddenInput value={formData.firstname} name="First name" />
    <FormHiddenInput value={formData.surname} name="Last name" />
    <FormHiddenInput value={formData.phone} name="Phone number" />
  </TransitionContainer>
))
