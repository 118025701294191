import { map, slice } from 'lodash'
import classNames from 'classnames'
import React, { memo } from 'react'
import { userService } from '@cotiss/user'
import { AVATAR_VARIANTS, Avatar, AvatarSize, AvatarState, Icon } from '@cotiss/common'

type Props = {
  className?: string
  state?: AvatarState
  users: { firstname?: string; surname?: string; firstName?: string; lastName?: string }[]
  size?: AvatarSize
  max?: number
  onAdd?: () => void
}

export const UserAvatarGroup = memo(({ className, state = 'outline', users, max = 3, size = 'md', onAdd }: Props) => {
  const classes = classNames(className, 'flex items-center')

  return (
    <div className={classes}>
      {map(slice(users, 0, max), (user, index) => (
        <Avatar
          key={index}
          className={index ? '-ml-1.5' : ''}
          title={userService.getFullName(user)}
          variant={AVATAR_VARIANTS[index % AVATAR_VARIANTS.length]}
          state={state}
          size={size}
        >
          {userService.getInitials(user)}
        </Avatar>
      ))}
      {users.length > max && (
        <Avatar className="-ml-1.5" size={size} variant="neutral" state={state}>
          {users.length - max}
        </Avatar>
      )}
      {onAdd && (
        <Avatar className="-ml-1.5" onClick={onAdd} size={size} variant="neutral">
          <Icon icon="plus" />
        </Avatar>
      )}
    </div>
  )
})
