import { SelectOption } from '@cotiss/common'
import { MetafieldFieldType } from '@cotiss/metafield'
import { map } from 'lodash'

export const METAFIELD_QUERY_KEYS = ['/v2/metafields'] as const

export const METAFIELD_FIELD_TYPE_LABEL_MAP: Record<MetafieldFieldType, string> = {
  SHORT_STRING: 'Short text',
  LONG_STRING: 'Long text',
  HYPERLINK: 'Hyperlink',
  ARRAY_STRING: 'Dropdown select',
  MULTI_ARRAY_STRING: 'Dropdown multi select',
  NUMBER: 'Number',
  DATE: 'Date',
  DATETIME: 'Date & time',
}

export const METAFIELD_FIELD_TYPE_OPTIONS: SelectOption<MetafieldFieldType>[] = map(METAFIELD_FIELD_TYPE_LABEL_MAP, (label, value) => ({
  label,
  value: value as MetafieldFieldType,
}))
