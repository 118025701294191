import { isAfter, parseISO } from 'date-fns'
import { GqlPerformanceScorecardMetricCycleFieldsFragment } from '@gql'
import { datetimeService } from '@cotiss/common'

class PerformanceService {
  getPerformanceMetricCycleStatus = ({ dateTo, status }: GqlPerformanceScorecardMetricCycleFieldsFragment) => {
    if (status === 'complete') {
      return 'complete'
    }

    if (isAfter(new Date(), parseISO(dateTo))) {
      return 'overdue'
    }

    return 'active'
  }

  getPerformanceMetricCycleLabel = ({ dateFrom, dateTo }: GqlPerformanceScorecardMetricCycleFieldsFragment) => {
    return `${datetimeService.format(dateFrom, 'do MMM yyyy')} – ${datetimeService.format(dateTo, 'do MMM yyyy')}`
  }
}

export const performanceService = new PerformanceService()
