import { compact, find, map } from 'lodash'
import React, { memo, useMemo } from 'react'
import { REGION_OPTIONS, GROUPED_REGION_OPTIONS, MultiSelectOption, MultiSelect } from '@cotiss/common'

type Props = {
  className?: string
  value: string[]
  onChange: (value: string[]) => void
  isRequired?: boolean
  isDisabled?: boolean
}

export const RegionMultiSelect = memo(({ className, value, onChange, isRequired, isDisabled }: Props) => {
  const selectValue = useMemo(() => compact(map(value, (region) => find(REGION_OPTIONS, ({ label }) => label === region))), [value])

  const handleChange = (selectValue: MultiSelectOption[]) => {
    onChange(map(selectValue, ({ label }) => label))
  }

  return (
    <MultiSelect
      className={className}
      value={selectValue}
      options={GROUPED_REGION_OPTIONS}
      onChange={handleChange}
      isRequired={isRequired}
      isDisabled={isDisabled}
    />
  )
})
