import { map, uniq } from 'lodash'
import CreatableSelect from 'react-select/creatable'
import React, { memo, useMemo, useState } from 'react'
import { GqlPerformanceMetricUnit } from '@gql'
import { PERFORMANCE_METRIC_UNIT_OPTIONS, usePerformanceMetric } from '@cotiss/performance'
import {
  Drawer,
  Input,
  Text,
  Button,
  sentryService,
  useCallout,
  useToast,
  Field,
  SelectOption,
  useAsyncEffect,
  Select,
  CURRENCY_OPTIONS,
  TextArea,
} from '@cotiss/common'

type FormData = {
  name: string
  group: string
  description: string
  methodology: string
  metricUnit: GqlPerformanceMetricUnit
  metricUnitType: string
}

type Props = {
  onSubmit: () => Promise<void>
}

export const SettingsModulesPerformanceMetricCreateDrawer = memo(({ onSubmit }: Props) => {
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { performanceMetricGroups, mutateCreatePerformanceMetric, queryPerformanceMetricGroupList } = usePerformanceMetric()
  const [formData, setFormData] = useState<FormData>({
    name: '',
    group: '',
    description: '',
    methodology: '',
    metricUnit: 'integer',
    metricUnitType: '',
  })

  const performanceMetricGroupOptions: SelectOption[] = useMemo(() => {
    return map(uniq([...performanceMetricGroups, 'Financial', 'Customer', 'Internal process', 'Learning & growth']), (value) => ({
      label: value,
      value,
    }))
  }, [performanceMetricGroups])

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      // TODO: We need to consider pagination here.
      await queryPerformanceMetricGroupList({ pagination: { page: 1, pageSize: 100 } })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsLoading(false)
  }, [])

  const handleSubmit = async () => {
    try {
      setIsSaving(true)
      await mutateCreatePerformanceMetric(formData)
      await onSubmit()
      closeDrawer()
    } catch (error: any) {
      openToast(error.message, 'danger')
      sentryService.captureException({ exception: error })
      setIsSaving(false)
    }
  }

  const renderHeader = () => (
    <Text className="font-medium mr-2" size="h5" font="jakarta">
      Add metric
    </Text>
  )

  const renderFooter = () => {
    return (
      <Button type="submit" variant="secondary" isLoading={isSaving}>
        Save
      </Button>
    )
  }

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <Field label="Group" supplementary="Used to categorize metrics.">
        <CreatableSelect
          value={formData.group ? { value: formData.group, label: formData.group } : undefined}
          options={performanceMetricGroupOptions}
          onChange={(category) => setFormData({ ...formData, group: category?.value || '' })}
          components={{ IndicatorSeparator: null }}
          noOptionsMessage={() => 'Type to create group...'}
          placeholder="Type to create group..."
          isDisabled={isLoading || isSaving}
          isMulti={false}
          required
          isClearable
        />
      </Field>
      <Field className="mt-8" label="Metric name">
        <Input
          value={formData.name}
          onChange={({ target }) => setFormData({ ...formData, name: target.value })}
          placeholder="Enter metric name..."
          isDisabled={isSaving}
          isRequired
        />
      </Field>
      <Field className="mt-8" label="Metric type (unit)">
        <div className="flex items-center justify-between">
          <Select<GqlPerformanceMetricUnit>
            value={formData.metricUnit}
            options={PERFORMANCE_METRIC_UNIT_OPTIONS}
            onChange={(metricUnit) => setFormData({ ...formData, metricUnit })}
            isDisabled={isSaving}
            isRequired
          />
          {formData.metricUnit === 'currency' && (
            <Select
              className="ml-4"
              value={formData.metricUnitType}
              options={CURRENCY_OPTIONS}
              onChange={(metricUnitType) => setFormData({ ...formData, metricUnitType: metricUnitType as string })}
              isDisabled={isSaving}
              isRequired
            />
          )}
        </div>
      </Field>
      <Field className="mt-8" label="Description" supplementary="Outline the metric in more detail">
        <TextArea
          value={formData.description}
          onChange={({ target }) => setFormData({ ...formData, description: target.value })}
          placeholder="Enter metric description..."
          rows={5}
          isDisabled={isSaving}
          isRequired
        />
      </Field>
      <Field className="mt-8" label="Methodology" supplementary="What is the methodology for determining the value of the metric">
        <TextArea
          value={formData.methodology}
          onChange={({ target }) => setFormData({ ...formData, methodology: target.value })}
          placeholder="Enter metric methodology..."
          rows={5}
          isDisabled={isSaving}
          isRequired
        />
      </Field>
    </Drawer>
  )
})
