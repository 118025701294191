import { map, uniq, upperFirst } from 'lodash'
import CreatableSelect from 'react-select/creatable'
import React, { memo, useMemo, useState } from 'react'
import { GqlPerformanceMetricFieldsFragment } from '@gql'
import { usePerformanceMetric } from '@cotiss/performance'
import {
  Drawer,
  Input,
  Text,
  Button,
  sentryService,
  useCallout,
  useToast,
  Field,
  SelectOption,
  useAsyncEffect,
  Select,
  CURRENCY_OPTIONS,
  TextArea,
  Icon,
  Tooltip,
} from '@cotiss/common'

type FormData = {
  name: string
  group: string
  description: string
  methodology: string
  metricUnitType: string
}

type Props = {
  performanceMetric: GqlPerformanceMetricFieldsFragment
  onSubmit: () => Promise<void>
  isEditing?: boolean
}

export const SettingsModulesPerformanceMetricUpdateDrawer = memo(({ performanceMetric, onSubmit, isEditing: isInitialEditing }: Props) => {
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(isInitialEditing)
  const { performanceMetricGroups, mutateUpdatePerformanceMetric, queryPerformanceMetricGroupList } = usePerformanceMetric()
  const [formData, setFormData] = useState<FormData>({
    name: performanceMetric.name || '',
    group: performanceMetric.group || '',
    description: performanceMetric.description || '',
    methodology: performanceMetric.methodology || '',
    metricUnitType: performanceMetric.metricUnitType || '',
  })

  const performanceMetricGroupOptions: SelectOption[] = useMemo(() => {
    return map(uniq([...performanceMetricGroups, 'Financial', 'Customer', 'Internal process', 'Learning & growth']), (value) => ({
      label: value,
      value,
    }))
  }, [performanceMetricGroups])

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      // TODO: We need to consider pagination here.
      await queryPerformanceMetricGroupList({ pagination: { page: 1, pageSize: 100 } })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsLoading(false)
  }, [])

  const handleSubmit = async () => {
    if (!isEditing) {
      return setIsEditing(true)
    }

    try {
      setIsSaving(true)
      await mutateUpdatePerformanceMetric({ performanceMetricId: performanceMetric.id, ...formData })
      await onSubmit()
      closeDrawer()
    } catch (error: any) {
      openToast(error.message, 'danger')
      sentryService.captureException({ exception: error })
      setIsSaving(false)
    }
  }

  const handleCancel = () => {
    setFormData({
      name: performanceMetric.name || '',
      group: performanceMetric.group || '',
      description: performanceMetric.description || '',
      methodology: performanceMetric.methodology || '',
      metricUnitType: performanceMetric.metricUnitType || '',
    })
    setIsEditing(false)
  }

  const renderHeader = () => (
    <Text className="font-medium mr-2" size="h5" font="jakarta">
      {isEditing ? 'Edit' : 'View'} metric
    </Text>
  )

  const renderFooter = () => (
    <>
      <Button type="submit" variant="secondary" state={isEditing ? 'filled' : 'outline'}>
        {isEditing ? 'Save' : 'Edit'}
      </Button>
      {isEditing && (
        <Button className="ml-4" onClick={() => handleCancel()} variant="secondary" state="text">
          Cancel
        </Button>
      )}
    </>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <Field label="Group" supplementary="Used to categorize metrics.">
        {isEditing && (
          <CreatableSelect
            value={formData.group ? { value: formData.group, label: formData.group } : undefined}
            options={performanceMetricGroupOptions}
            onChange={(category) => setFormData({ ...formData, group: category?.value || '' })}
            components={{ IndicatorSeparator: null }}
            noOptionsMessage={() => 'Type to create group...'}
            placeholder="Type to create group..."
            isDisabled={isLoading || isSaving}
            isMulti={false}
            required
            isClearable
          />
        )}
        {!isEditing && <Text>{performanceMetric.group}</Text>}
      </Field>
      <Field className="mt-8" label="Metric name">
        {isEditing && (
          <Input
            value={formData.name}
            onChange={({ target }) => setFormData({ ...formData, name: target.value })}
            placeholder="Enter metric name..."
            isDisabled={isSaving}
            isRequired
          />
        )}
        {!isEditing && <Text>{performanceMetric.name}</Text>}
      </Field>
      <Field className="mt-8" label="Metric type (unit)">
        <div className="flex items-center justify-between">
          {isEditing && (
            <>
              <div className="relative w-full">
                <Input className="pointer-events-none w-full pr-12" value={upperFirst(performanceMetric.metricUnit)} />
                <Tooltip className="right-3 top-1/2 -translate-y-1/2" tooltip="Metrics can't be edited once used in a scorecard" isAbsolute>
                  <Icon icon="lock" />
                </Tooltip>
              </div>
              {performanceMetric.metricUnit === 'currency' && (
                <Select
                  className="ml-4"
                  value={formData.metricUnitType}
                  options={CURRENCY_OPTIONS}
                  onChange={(metricUnitType) => setFormData({ ...formData, metricUnitType: metricUnitType as string })}
                  isDisabled={isSaving}
                  isRequired
                />
              )}
            </>
          )}
          {!isEditing && (
            <>
              <Text>{upperFirst(performanceMetric.metricUnit)}</Text>
              {performanceMetric.metricUnit === 'currency' && <Text>{performanceMetric.metricUnitType}</Text>}
            </>
          )}
        </div>
      </Field>
      <Field className="mt-8" label="Description" supplementary="Outline the metric in more detail">
        {isEditing && (
          <TextArea
            value={formData.description}
            onChange={({ target }) => setFormData({ ...formData, description: target.value })}
            placeholder="Enter metric description..."
            rows={5}
            isDisabled={isSaving}
            isRequired
          />
        )}
        {!isEditing && <Text>{performanceMetric.description}</Text>}
      </Field>
      <Field className="mt-8" label="Methodology" supplementary="What is the methodology for determining the value of the metric">
        {isEditing && (
          <TextArea
            value={formData.methodology}
            onChange={({ target }) => setFormData({ ...formData, methodology: target.value })}
            placeholder="Enter metric methodology..."
            rows={5}
            isDisabled={isSaving}
            isRequired
          />
        )}
        {!isEditing && <Text>{performanceMetric.methodology}</Text>}
      </Field>
    </Drawer>
  )
})
