import { compact, includes, map, some } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { GqlPagination, GqlUpdatePerformanceScorecardMetricInput } from '@gql'
import { userService } from '@cotiss/user'
import {
  PerformanceScorecardMetricCreateDrawer,
  PerformanceScorecardMetricViewUpdateDrawer,
  usePerformanceScorecard,
  usePerformanceScorecardAnalytics,
  usePerformanceScorecardMetric,
  usePerformanceScorecardUser,
} from '@cotiss/performance'
import {
  Avatar,
  Button,
  ConfirmModal,
  datetimeService,
  ErrorPanel,
  Icon,
  routerService,
  ScrollableTable,
  ScrollableTableColumn,
  sentryService,
  TableEmptyContainer,
  TableHeader,
  TableRowCta,
  TableSubHeader,
  TabModel,
  Tabs,
  Text,
  useAsyncEffect,
  useCallout,
} from '@cotiss/common'

export type PerformanceScorecardMetricsTab = 'archived' | 'active'
const PERFORMANCE_SCORECARD_METRICS_TABS: TabModel<PerformanceScorecardMetricsTab>[] = [
  { id: 'active', label: 'Active' },
  { id: 'archived', label: 'Archived' },
]

export const PerformanceScorecardViewMetricsTab = memo(() => {
  const { push, replace } = useHistory()
  const [isError, setIsError] = useState(false)
  const { openModal, openDrawer } = useCallout()
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const { track } = usePerformanceScorecardAnalytics()
  const [pagination, setPagination] = useState<GqlPagination>()
  const { performanceScorecardUserInSession } = usePerformanceScorecardUser()
  const { performanceScorecard, queryPerformanceScorecardView } = usePerformanceScorecard()
  const {
    performanceScorecardMetrics,
    queryPerformanceScorecardMetricList,
    mutateUpdatePerformanceScorecardMetric,
    mutateDeletePerformanceScorecardMetric,
  } = usePerformanceScorecardMetric()
  const { performanceScorecardId, tab, nestedTab } = useParams<{
    performanceScorecardId: string
    tab: string
    nestedTab?: PerformanceScorecardMetricsTab
  }>()

  useEffect(() => {
    track('performance_scorecard_metric_list_active_view')
  }, [])

  useEffect(() => {
    if (!nestedTab || !some(PERFORMANCE_SCORECARD_METRICS_TABS, { id: nestedTab })) {
      return replace(
        routerService.getHref('/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?', {
          performanceScorecardId,
          tab,
          nestedTab: 'active',
        })
      )
    }
  }, [nestedTab])

  const handleDeletePerformanceScorecardMetric = async (performanceScorecardMetricId: string) => {
    if (!performanceScorecard) {
      return
    }

    await mutateDeletePerformanceScorecardMetric({ performanceScorecardMetricId })
    await Promise.all([refreshPerformanceMetricList(), queryPerformanceScorecardView({ performanceScorecardId: performanceScorecard.id })])
  }

  const handleUpdatePerformanceScorecardMetric = async (input: GqlUpdatePerformanceScorecardMetricInput) => {
    if (!performanceScorecard) {
      return
    }

    await mutateUpdatePerformanceScorecardMetric(input)
    await Promise.all([refreshPerformanceMetricList(), queryPerformanceScorecardView({ performanceScorecardId: performanceScorecard.id })])
  }

  const { fixedColumns, columns, isOwner } = useMemo(() => {
    const isOwner = includes(performanceScorecardUserInSession?.roles, 'owner')

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Metric',
        rows: map(performanceScorecardMetrics, ({ id: performanceScorecardMetricId, performanceMetric, isArchived }) => ({
          content: () => (
            <Text className="truncate" font="jakarta" title={performanceMetric.name}>
              {performanceMetric.name}
            </Text>
          ),
          cta: (
            <TableRowCta
              cta={{
                label: (
                  <>
                    View <Icon icon="arrow-right" />
                  </>
                ),
                onClick: () =>
                  openDrawer(
                    <PerformanceScorecardMetricViewUpdateDrawer
                      performanceScorecardMetricId={performanceScorecardMetricId}
                      onSubmit={refreshPerformanceMetricList}
                    />
                  ),
              }}
              actions={
                isOwner
                  ? compact([
                      performanceScorecard?.status === 'draft' && {
                        label: 'Delete',
                        onClick: () => {
                          openModal(
                            <ConfirmModal
                              heading="Delete metric"
                              description="Are you sure you want to delete this metric?"
                              onSubmit={() => handleDeletePerformanceScorecardMetric(performanceScorecardMetricId)}
                            />
                          )
                        },
                      },
                      performanceScorecard?.status === 'active' && {
                        label: isArchived ? 'Unarchive' : 'Archive',
                        onClick: () => {
                          openModal(
                            <ConfirmModal
                              heading={`${isArchived ? 'Unarchive' : 'Archive'} metric`}
                              description={`Are you sure you want to ${isArchived ? 'unarchive' : 'archive'} this metric?`}
                              onSubmit={() => handleUpdatePerformanceScorecardMetric({ performanceScorecardMetricId, isArchived: !isArchived })}
                            />
                          )
                        },
                        isDisabled: !isArchived && (pagination?.totalCount || 0) <= 1,
                        disabledTooltip: 'You must have at least 1 active metric on a published scorecard.',
                      },
                    ])
                  : undefined
              }
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Group',
        rows: map(performanceScorecardMetrics, ({ performanceMetric }) => ({
          content: () => <Text>{performanceMetric.group}</Text>,
        })),
      },
      {
        heading: 'Description',
        isWrapped: true,
        rows: map(performanceScorecardMetrics, ({ performanceMetric }) => ({
          content: () => <Text>{performanceMetric.description}</Text>,
        })),
      },
      {
        heading: 'Created by',
        rows: map(performanceScorecardMetrics, ({ performanceMetric }) => ({
          content: () =>
            performanceMetric.createdByUser ? <Avatar size="sm">{userService.getInitials(performanceMetric.createdByUser)}</Avatar> : '--',
        })),
      },
      {
        heading: 'Created at',
        rows: map(performanceScorecardMetrics, ({ createdAt }) => ({
          content: () => <Text>{datetimeService.format(createdAt, 'do MMM yyyy')}</Text>,
        })),
      },
    ]

    return { fixedColumns, columns, isOwner }
  }, [performanceScorecardMetrics, pagination, performanceScorecardUserInSession])

  const refreshPerformanceMetricList = async () => {
    try {
      setIsLoading(true)
      const { pagination } = await queryPerformanceScorecardMetricList({
        filter: {
          performanceScorecardId,
          isArchived: nestedTab === 'archived',
        },
        pagination: {
          page: currentPage,
          pageSize: 100,
        },
      })
      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }

  useAsyncEffect(async () => {
    await refreshPerformanceMetricList()
  }, [currentPage, performanceScorecardId, nestedTab])

  if (!isLoading && isError) {
    return <ErrorPanel />
  }

  if (!performanceScorecard) {
    return null
  }

  return (
    <>
      <TableHeader className="flex items-center justify-between" variant="white">
        <div>
          <Text className="font-medium" size="lg">
            Metrics
          </Text>
          <Text className="mt-1" size="sm" variant="light">
            Below are the metrics that we are tracking in this scorecard
          </Text>
        </div>
        {isOwner && (
          <Button
            className="ml-2"
            onClick={() =>
              openDrawer(
                <PerformanceScorecardMetricCreateDrawer performanceScorecardId={performanceScorecardId} onSubmit={refreshPerformanceMetricList} />
              )
            }
            variant="secondary"
            state="translucent"
            size="sm"
          >
            <Icon icon="plus" /> Add metric
          </Button>
        )}
      </TableHeader>
      {performanceScorecard?.status === 'active' && (
        <TableSubHeader variant="white">
          <Tabs<PerformanceScorecardMetricsTab>
            tab={nestedTab}
            tabs={PERFORMANCE_SCORECARD_METRICS_TABS}
            onChange={({ id: nestedTab }) => {
              push(
                routerService.getHref('/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?', {
                  performanceScorecardId,
                  tab,
                  nestedTab,
                })
              )
            }}
          />
        </TableSubHeader>
      )}
      {Boolean(isLoading || performanceScorecardMetrics.length) && (
        <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
      )}
      {!isLoading && !performanceScorecardMetrics.length && (
        <TableEmptyContainer>
          <Text variant="light">
            {nestedTab === 'archived'
              ? 'You have no archived performance scorecard metrics.'
              : 'Select metrics from your performance library to add to your scorecard.'}
          </Text>
        </TableEmptyContainer>
      )}
    </>
  )
})
