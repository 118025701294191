import { filter, find, forEach, map } from 'lodash'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useListMetafield } from '@cotiss/metafield'
import { UserAvatarGroup, useGetLoggedInUser } from '@cotiss/user'
import { metafieldValueService, useListMetafieldValue } from '@cotiss/metafield-value'
import {
  ContractCreateDrawer,
  ContractShellFilterPopulatedModel,
  ContractStatusBadge,
  ContractVariationStatusBadge,
  FilterContractShellSortKey,
  contractService,
  useMutateContractShell,
} from '@cotiss/contract'
import {
  Badge,
  Button,
  FilterDrawer_DEPRECATED,
  FilterFieldOptions_DEPRECATED,
  Filter_DEPRECATED,
  NoDataPlaceholder,
  PaginationModel,
  Radio,
  ScrollableTable,
  ScrollableTableColumn,
  TableHeader,
  Text,
  datetimeService,
  filterService_DEPRECATED,
  sentryService,
  useCallout,
  useFeature,
  useSortTable,
  useToast,
  utilService,
} from '@cotiss/common'

type Props = {
  contractShellId: string
  selectedContractShell?: ContractShellFilterPopulatedModel
  onContractShellChange: (contractShell: ContractShellFilterPopulatedModel) => void
  isDisabled?: boolean
}

export const ContractLinkContractStep = memo(({ contractShellId, selectedContractShell, onContractShellChange, isDisabled }: Props) => {
  const { openToast } = useToast()
  const { user } = useGetLoggedInUser()
  const [currentPage, setCurrentPage] = useState(1)
  const { openDrawer, openNarrowDrawer } = useCallout()
  const { filterContractShell } = useMutateContractShell()
  const [pagination, setPagination] = useState<PaginationModel>()
  const [isLoadingContractShells, setIsLoadingContractShells] = useState(false)
  const [filterFields, setFilterFields] = useState<FilterFieldOptions_DEPRECATED>({})
  const [contractShells, setContractShell] = useState<ContractShellFilterPopulatedModel[]>([])
  const [filters, setFilters] = useState<Filter_DEPRECATED[]>(filterService_DEPRECATED.getFiltersFromUrl())
  const isContractManagementListViewMetafieldsEnabled = useFeature('contract-management-list-view-metafields')
  const { sortKey, sortDirection, onSort } = useSortTable<FilterContractShellSortKey>({ initialKey: 'createdAt', initialSortDirection: 'desc' })
  const { metafields, isFetching: isLoadingMetafields } = useListMetafield({
    entityType: 'CONTRACT',
    isEnabled: isContractManagementListViewMetafieldsEnabled,
  })
  const { metafieldValues, isFetching: isLoadingMetafieldValues } = useListMetafieldValue({
    resourceIds: map(contractShells, (contractShell) => contractShell.contracts._id),
    isEnabled: isContractManagementListViewMetafieldsEnabled,
  })
  const isLoading = isLoadingContractShells || isLoadingMetafields || isLoadingMetafieldValues

  const processedFilters = useMemo(() => {
    return [
      ...filters,
      {
        field: 'archived',
        operation: 'IS_FALSE',
        value: true,
      },
    ] as Filter_DEPRECATED[]
  }, [filters])

  const refreshContractShells = async () => {
    try {
      setIsLoadingContractShells(true)

      const { contractShells, pagination, meta } = await filterContractShell({
        filters: processedFilters,
        currentPage: currentPage,
        pageSize: 20,
        sort: sortKey,
        order: sortDirection,
      })

      setContractShell(contractShells)
      setPagination(pagination)

      // Remove archived filter from the UI; the user cannot override it so they needn't see it in the UI
      Object.hasOwn(meta, 'archived') && delete meta.archived

      setFilterFields(meta)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
    setIsLoadingContractShells(false)
  }

  useEffect(() => {
    refreshContractShells()
  }, [processedFilters, currentPage, sortKey, sortDirection])

  const renderButtons = () => (
    <Button
      onClick={() => openNarrowDrawer(<FilterDrawer_DEPRECATED filters={filters} filterFields={filterFields} setFilters={setFilters} />)}
      size="xs"
      variant="secondary"
      state="outline"
      isDisabled={isLoading}
    >
      + Filters ({filters.length})
    </Button>
  )

  const { fixedColumns, columns } = useMemo(() => {
    // Don't allow user to link contract shell to itself.
    const filteredContractShell = filter(contractShells, ({ _id }) => _id !== contractShellId)

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: ' ',
        thClassName: 'w-12',
        rows: map(filteredContractShell, (contractShell) => ({
          content: () => (
            <Radio
              value={contractShell._id}
              name="contract-link-contract"
              onChange={() => onContractShellChange(contractShell)}
              isChecked={contractShell._id === selectedContractShell?._id}
              isDisabled={isDisabled}
              isRequired
            />
          ),
        })),
      },
      {
        heading: 'Title',
        onSort: () => onSort('title'),
        rows: map(filteredContractShell, ({ title }) => ({
          content: () => (
            <Text className="truncate" title={title} font="jakarta">
              {title}
            </Text>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Contract status',
        rows: map(filteredContractShell, ({ contracts }) => ({
          content: () => <ContractStatusBadge status={contracts?.status} />,
        })),
      },
      {
        heading: 'Owners',
        rows: map(filteredContractShell, ({ contracts }) => ({
          content: () => (
            <div className="flex items-center">
              <UserAvatarGroup users={contracts.metadata.owners} max={3} state="outline" size="sm" />
            </div>
          ),
        })),
      },
      {
        heading: 'Variation status',
        rows: map(filteredContractShell, ({ variationStatus }) => ({
          content: () => {
            return <ContractVariationStatusBadge status={variationStatus} />
          },
        })),
      },
      {
        heading: 'Total exercised',
        onSort: () => onSort('totalExercised'),
        rows: map(
          filteredContractShell,
          ({
            totalExercised,
            contracts: {
              metadata: { currency },
            },
          }) => ({
            content: () => (
              <Text variant="light" className="truncate" size="sm">
                {currency && `${utilService.formatAsCurrency(totalExercised, currency)}`}
              </Text>
            ),
            tdClassName: 'max-w-[350px]',
          })
        ),
      },
      {
        heading: 'Total value',
        onSort: () => onSort('totalValue'),
        rows: map(
          filteredContractShell,
          ({
            totalValue,
            contracts: {
              metadata: { currency },
            },
          }) => ({
            content: () => (
              <Text variant="light" className="truncate" size="sm">
                {currency && `${utilService.formatAsCurrency(totalValue, currency)}`}
              </Text>
            ),
            tdClassName: 'max-w-[350px]',
          })
        ),
      },
      {
        heading: 'Next expiry',
        rows: map(filteredContractShell, ({ contracts: { priceDurations } }) => ({
          content: () => {
            const nextExpiry = contractService.getNextExpirationDate(priceDurations)
            return (
              <Text size="sm" variant="light">
                {nextExpiry ? datetimeService.format(nextExpiry, 'do MMM yyyy') : '--'}
              </Text>
            )
          },
          tdClassName: 'max-w-[350px]',
        })),
      },
      {
        heading: 'Procurement',
        onSort: () => onSort('procurementTitle'),
        rows: map(filteredContractShell, ({ procurement }) => ({
          content: () => (
            <Text variant="light" className="truncate" size="sm">
              {procurement?.title || '--'}
            </Text>
          ),
          tdClassName: 'max-w-[350px]',
        })),
      },
      {
        heading: 'Date created',
        onSort: () => onSort('createdAt'),
        rows: map(filteredContractShell, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
      {
        heading: 'Contract ID',
        rows: map(filteredContractShell, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.internalReference || '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Internal reference',
        rows: map(filteredContractShell, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.externalReference || '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Counterparties',
        rows: map(filteredContractShell, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.suppliers.map((counterparty) => counterparty.name).join(', ') || '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Contracting entity',
        rows: map(filteredContractShell, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.contractingEntity?.name || '--'}
            </Text>
          ),
        })),
      },
    ]

    // Add metafields columns
    if (isContractManagementListViewMetafieldsEnabled) {
      forEach(metafields, (metafield) => {
        columns.push({
          heading: metafield.fieldLabel,
          rows: map(filteredContractShell, ({ contracts: { _id: contractId } }) => ({
            tdClassName: 'max-w-[350px]',
            content: () => {
              const metafieldValue = find(metafieldValues, { metafield: metafield._id, resourceId: contractId })
              const processedMetafieldValue = metafieldValueService.renderFieldValue({ metafield, metafieldValue })

              if (metafield.fieldType === 'HYPERLINK' && metafieldValue) {
                return (
                  <Button isExternalLink isTruncated href={processedMetafieldValue} variant="secondary" state="text">
                    {processedMetafieldValue}
                  </Button>
                )
              }

              return (
                <Text className="whitespace-pre-wrap line-clamp-3" size="sm" variant="light" title={processedMetafieldValue}>
                  {processedMetafieldValue}
                </Text>
              )
            },
          })),
        })
      })
    }

    return { fixedColumns, columns }
  }, [selectedContractShell, contractShellId, contractShells, user, metafields, metafieldValues, isContractManagementListViewMetafieldsEnabled])

  if (!isLoading && !contractShells.length) {
    return (
      <div>
        {renderButtons()}
        <div className="flex items-center justify-center h-60 bg-gray-200 rounded">
          <NoDataPlaceholder
            illustration="dot-list"
            variant="transparent"
            label={
              filters.length
                ? 'No contracts have been found'
                : "You haven't created any contracts yet. Once created, you can track your contracts here"
            }
            ctaSize="xs"
            ctaLabel="+ Create contract"
            onCtaClick={() => openDrawer(<ContractCreateDrawer />)}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <TableHeader className="flex items-center justify-between">
        <Text className="font-semibold" font="jakarta">
          Select the contract.
        </Text>
        <Badge variant="secondary" state="translucent">
          Step 1 of 2
        </Badge>
      </TableHeader>
      <div className="border-t border-x border-grey-200 p-4">{renderButtons()}</div>
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    </>
  )
})
