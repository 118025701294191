import { map } from 'lodash'
import { isBefore } from 'date-fns'
import { useHistory, useParams } from 'react-router-dom'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { AppErrorPage } from '@cotiss/app'
import {
  PerformanceScorecardMetricCycleStatusBadge,
  performanceService,
  usePerformanceScorecard,
  usePerformanceScorecardAnalytics,
  usePerformanceScorecardMetric,
  usePerformanceScorecardMetricCycle,
  usePerformanceScorecardUser,
} from '@cotiss/performance'
import {
  Breadcrumb,
  BreadcrumbModel,
  datetimeService,
  FourOhThreePage,
  Header,
  Icon,
  Page,
  PageContent,
  routerService,
  ScrollableTable,
  ScrollableTableColumn,
  sentryService,
  Skeleton,
  TableEmptyContainer,
  TableHeader,
  TableRowCta,
  Text,
  useAsyncEffect,
  useFeature,
  useToast,
} from '@cotiss/common'

export const PerformanceScorecardMetricViewPage = memo(() => {
  const { openToast } = useToast()
  const { push, replace } = useHistory()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const { track } = usePerformanceScorecardAnalytics()
  const [pagination, setPagination] = useState<GqlPagination>()
  const isPerformanceScorecardEnabled = useFeature('performance-scorecard')
  const { queryPerformanceScorecardUserInSessionView } = usePerformanceScorecardUser()
  const { performanceScorecard, queryPerformanceScorecardView } = usePerformanceScorecard()
  const { performanceScorecardMetric, queryPerformanceScorecardMetricView } = usePerformanceScorecardMetric()
  const { performanceScorecardMetricCycles, queryPerformanceScorecardMetricCycleList } = usePerformanceScorecardMetricCycle()
  const { performanceScorecardId, performanceScorecardMetricId } = useParams<{
    performanceScorecardId: string
    performanceScorecardMetricId: string
  }>()

  const backHref = routerService.getHref('/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?', {
    performanceScorecardId,
    tab: 'tracking',
  })
  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Performance',
      href: routerService.getHref('/performance/scorecard/list/:tab?'),
    },
    {
      label: performanceScorecard?.name || '',
      href: backHref,
      isLoading,
    },
    {
      label: performanceScorecardMetric?.performanceMetric.name || '',
      isLoading,
    },
  ]

  useAsyncEffect(async () => {
    try {
      const [performanceScorecard, performanceScorecardMetric] = await Promise.all([
        queryPerformanceScorecardView({ performanceScorecardId }),
        queryPerformanceScorecardMetricView({ performanceScorecardMetricId }),
        queryPerformanceScorecardUserInSessionView({ performanceScorecardId }),
      ])

      track('performance_scorecard_metric_view')

      if (performanceScorecard.status === 'draft') {
        openToast('You do not have permission to view this performance scorecard metric.', 'danger')
        replace(backHref)
        return
      }

      if (isBefore(new Date(), datetimeService.parse(performanceScorecardMetric.startDate))) {
        openToast("You cannot view a performance scorecard metric before it's start date.", 'danger')
        replace(backHref)
        return
      }
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!performanceScorecard) {
      return
    }

    if (performanceScorecard.isArchived) {
      openToast('You cannot access an archived performance scorecard.', 'danger')
      return push(routerService.getHref('/performance/scorecard/list/:tab?'))
    }
  }, [performanceScorecard])

  const refreshPerformanceMetricCycleList = async () => {
    try {
      setIsLoading(true)
      const { pagination } = await queryPerformanceScorecardMetricCycleList({
        filter: { performanceScorecardMetricId },
        pagination: {
          page: currentPage,
          pageSize: 100,
        },
      })
      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }

  useAsyncEffect(async () => {
    await refreshPerformanceMetricCycleList()
  }, [currentPage, performanceScorecardMetricId])

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Cycle',
        rows: map(performanceScorecardMetricCycles, (performanceScorecardMetricCycle) => ({
          content: () => {
            const label = performanceService.getPerformanceMetricCycleLabel(performanceScorecardMetricCycle)

            return (
              <Text className="truncate" font="jakarta" title={label}>
                {label}
              </Text>
            )
          },
          cta: (
            <TableRowCta
              cta={{
                label: (
                  <>
                    View <Icon icon="arrow-right" />
                  </>
                ),
                href: routerService.getHref(
                  '/performance/scorecard/view/:performanceScorecardId/metric/:performanceScorecardMetricId/cycle/:performanceScorecardMetricCycleId',
                  {
                    performanceScorecardId,
                    performanceScorecardMetricId,
                    performanceScorecardMetricCycleId: performanceScorecardMetricCycle.id,
                  }
                ),
              }}
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Status',
        rows: map(performanceScorecardMetricCycles, (performanceScorecardMetricCycle) => ({
          content: () => <PerformanceScorecardMetricCycleStatusBadge performanceScorecardMetricCycle={performanceScorecardMetricCycle} />,
        })),
      },
      {
        heading: 'Final value',
        rows: map(performanceScorecardMetricCycles, ({ score }) => ({
          content: () => <Text>{score || '--'}</Text>,
        })),
      },
      {
        heading: 'Date submitted',
        rows: map(performanceScorecardMetricCycles, ({ submittedAt }) => ({
          content: () => <Text>{submittedAt ? datetimeService.format(submittedAt, 'do MMM yyyy') : '--'}</Text>,
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [performanceScorecardMetricCycles])

  if (!isLoading && isError) {
    return <AppErrorPage />
  }

  if (!isPerformanceScorecardEnabled) {
    return <FourOhThreePage />
  }

  return (
    <Page>
      <Header>
        <Breadcrumb className="mb-1" backHref={backHref} breadcrumbs={breadcrumbs} isDisabled={isLoading} />
        {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
        {!isLoading && performanceScorecardMetric && (
          <Text className="font-medium flex items-center" size="h7" variant="heading" font="jakarta">
            {performanceScorecardMetric?.performanceMetric.name}
          </Text>
        )}
      </Header>
      <PageContent>
        <TableHeader variant="white">
          <Text className="font-medium" size="lg">
            Frequency cycles
          </Text>
          <Text className="mt-1" size="sm" variant="light">
            All cycles outstanding and completed for this metric.
          </Text>
        </TableHeader>
        {Boolean(isLoading || performanceScorecardMetricCycles.length) && (
          <ScrollableTable
            fixedColumns={fixedColumns}
            columns={columns}
            pagination={pagination}
            onPageChange={setCurrentPage}
            isLoading={isLoading}
          />
        )}
        {!isLoading && !performanceScorecardMetricCycles.length && (
          <TableEmptyContainer>
            <Text variant="light">No cycles have been generated.</Text>
          </TableEmptyContainer>
        )}
      </PageContent>
    </Page>
  )
})
