import { map } from 'lodash'
import React, { memo } from 'react'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { BuyerProcurementItem } from '@cotiss/buyer'
import { useListPublicProcurement } from '@cotiss/procurement'
import { Banner, NoDataPlaceholder, Text, utilService } from '@cotiss/common'

export const BuyerViewOpenListingsTab = memo(() => {
  const { id } = useParams<{ id: string }>()
  const { publicProcurements, meta } = useListPublicProcurement({ organisationId: id, open: true })

  const showBanner = meta?.privateListingsCount !== undefined && meta.privateListingsCount > 0

  return (
    <>
      {showBanner && (
        <Banner className="mb-6" variant="neutral">
          <div className="mr-6">
            <Text size="sm">
              {meta.privateListingsCount} open private {utilService.pluralize(meta.privateListingsCount, 'listing')}. Private listings are invite only
              and cannot be viewed in this portal.
            </Text>
          </div>
        </Banner>
      )}
      {!publicProcurements.length && showBanner && <NoDataPlaceholder label="No public listings" />}
      {!publicProcurements.length && !showBanner && <NoDataPlaceholder label="No listings" />}
      {Boolean(publicProcurements.length) && (
        <div>
          {map(publicProcurements, (publicProcurement, index) => (
            <BuyerProcurementItem
              key={publicProcurement.tender._id}
              className={classNames({ 'mt-4': index })}
              publicProcurement={publicProcurement}
            />
          ))}
        </div>
      )}
    </>
  )
})
