import { find, map, toLower, upperFirst } from 'lodash'
import React, { memo, useMemo } from 'react'
import CreatableSelect from 'react-select/creatable'
import { MultiSelectOption } from '@cotiss/common'

type Props = {
  className?: string
  value?: string
  options: string[]
  onChange: (value: string) => void
  onCreate: (value: string) => void
  onClear: () => void
  isRequired?: boolean
  isDisabled?: boolean
}

export const ContractScheduledRateTagSelect = memo(({ className, value, options, onChange, onCreate, onClear, isRequired, isDisabled }: Props) => {
  const { tagValue, tagOptions } = useMemo(() => {
    const tagOptions = map(options, (option) => ({
      value: option,
      label: upperFirst(toLower(option)),
    }))
    const tagValue = (find(tagOptions, { value }) || {
      label: value,
      value,
    }) as MultiSelectOption

    return { tagValue, tagOptions }
  }, [value, options])

  const handleChange = (tagValue?: MultiSelectOption) => {
    if (tagValue) {
      onChange(tagValue.value)
    } else {
      onClear()
    }
  }

  const handleCreate = (value: string) => {
    onChange(value)
    onCreate(value)
  }

  return (
    <div className="relative">
      <CreatableSelect
        className={className}
        value={value ? tagValue : undefined}
        options={tagOptions}
        onChange={(tagValue) => handleChange(tagValue as MultiSelectOption)}
        onCreateOption={handleCreate}
        placeholder="Search or create tag..."
        noOptionsMessage={() => 'Type to create tag...'}
        required={isRequired}
        isDisabled={isDisabled}
        isClearable={!isDisabled}
        components={{ IndicatorSeparator: null }}
        styles={{
          singleValue: (base) => ({
            ...base,
            backgroundColor: '#F0F1F3', // Cool grey 200
            color: '#6B7280', // Cool grey 500
            borderRadius: '24px',
            display: 'flex',
            paddingLeft: 8,
            paddingRight: 8,
            lineHeight: 2,
            fontSize: '10px',
            width: 'fit-content',
          }),
        }}
      />
    </div>
  )
})
