import { AnimatePresence } from 'framer-motion'
import { filter, findIndex, includes } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationScoreFieldsFragment } from '@gql'
import {
  AsyncTextArea,
  Label,
  TabModel,
  Tabs,
  TransitionContainer,
  sentryService,
  Text,
  useAsyncEffect,
  useToast,
  useTransition,
  Hr,
  ViewMoreContainer,
  Button,
} from '@cotiss/common'
import {
  EVALUATION_EVENT_RATING_SCALE_NAME_MAP,
  EvaluationEventCriteriaScoreComparisonList,
  EvaluationEventRatingScaleDefinitionList,
  EvaluationEventRatingScaleValueField,
  IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES,
  useEvaluation,
  useEvaluationEvent,
  useEvaluationScore,
} from '@cotiss/evaluation-event'

type Tab = 'score' | 'scale-definitions' | 'compare-scores'

type HandleScoreSubmitParam = {
  value: string
  evaluationScore?: GqlEvaluationScoreFieldsFragment
}

type HandleCommentSubmitParam = {
  comment: string
  evaluationScore?: GqlEvaluationScoreFieldsFragment
}

type Props = {
  evaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
  parentEvaluationCriteriaItem?: GqlEvaluationCriteriaFieldsFragment | null
}

export const EvaluationEventScoreDrawerPanel = memo(({ evaluationCriteriaItem, parentEvaluationCriteriaItem }: Props) => {
  const { openToast } = useToast()
  const [comment, setComment] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationEvent } = useEvaluationEvent()
  const { step, transition, onTransition } = useTransition()
  const [evaluationScore, setEvaluationScore] = useState<GqlEvaluationScoreFieldsFragment>()
  const { evaluation, queryEvaluationOverviewView, mutateUpdateEvaluation } = useEvaluation()
  const { queryEvaluationScoreList, mutateCreateEvaluationScore, mutateUpdateEvaluationScore } = useEvaluationScore()
  const isDisabled = !evaluation || evaluation.status === 'complete' || evaluation.status === 'abstained'

  const { tabs, isRatingScaleVisible } = useMemo(() => {
    const isRatingScaleVisible = includes(IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES, evaluationCriteriaItem.ratingScale)

    const tabs: TabModel<Tab & { isHidden?: boolean }>[] = [
      {
        id: 'score',
        label: 'Score',
      },
      {
        id: 'scale-definitions',
        label: 'Rating scale definitions',
        isHidden: !isRatingScaleVisible,
      },
      {
        id: 'compare-scores',
        label: 'Compare scores',
      },
    ]

    return { tabs: filter(tabs, (tab) => !tab.isHidden), isRatingScaleVisible }
  }, [])

  const tab = useMemo(() => tabs[step - 1].id, [step, tabs])

  useAsyncEffect(async () => {
    if (!evaluationEvent || !evaluation) {
      return
    }

    const { evaluationScores } = await queryEvaluationScoreList({
      filter: {
        evaluationEventId: evaluationEvent.id,
        evaluationId: evaluation.id,
        evaluationCriteriaId: evaluationCriteriaItem.id,
      },
    })
    setEvaluationScore(evaluationScores[0])
    setComment(evaluationScores[0]?.comment || '')
  }, [])

  const handleTabChange = (tab: Tab) => {
    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1

    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  const handleScoreSubmit = async ({ value, evaluationScore }: HandleScoreSubmitParam) => {
    try {
      if (!evaluation || !evaluationEvent) {
        return
      }

      setIsSaving(true)
      evaluationScore
        ? await mutateUpdateEvaluationScore({ evaluationScoreId: evaluationScore.id, value: Number(value) })
        : await mutateCreateEvaluationScore({ evaluationCriteriaId: evaluationCriteriaItem.id, evaluationId: evaluation.id, value: Number(value) })

      const [{ evaluationScores }] = await Promise.all([
        queryEvaluationScoreList({
          filter: { evaluationEventId: evaluationEvent.id, evaluationId: evaluation.id, evaluationCriteriaId: evaluationCriteriaItem.id },
        }),
        queryEvaluationOverviewView({ evaluationId: evaluation.id }),
        evaluation.status !== 'inProgress' && mutateUpdateEvaluation({ evaluationId: evaluation.id, status: 'inProgress' }),
      ])
      setEvaluationScore(evaluationScores[0])
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleCommentSubmit = async ({ comment, evaluationScore }: HandleCommentSubmitParam) => {
    try {
      if (!evaluation) {
        return
      }

      setIsSaving(true)
      evaluationScore
        ? await mutateUpdateEvaluationScore({ evaluationScoreId: evaluationScore.id, comment })
        : await mutateCreateEvaluationScore({ evaluationCriteriaId: evaluationCriteriaItem.id, evaluationId: evaluation.id, comment })

      await Promise.all([
        queryEvaluationOverviewView({ evaluationId: evaluation.id }),
        evaluation.status !== 'inProgress' && mutateUpdateEvaluation({ evaluationId: evaluation.id, status: 'inProgress' }),
      ])
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <>
      <div className="bg-primary-50 rounded-xl px-4 py-5">
        {parentEvaluationCriteriaItem && (
          <Text className="mt-1" variant="light" size="sm">
            {parentEvaluationCriteriaItem.index}. {parentEvaluationCriteriaItem.content}
          </Text>
        )}
        <Text className="mr-4 font-medium" size="h4">
          {parentEvaluationCriteriaItem ? `${parentEvaluationCriteriaItem.index}.` : ''}
          {evaluationCriteriaItem.index}. {evaluationCriteriaItem.content}
        </Text>

        <Hr className="my-4" />

        <Text className="font-medium">Rating scale</Text>
        <div className="flex items-center">
          <Text className="font-medium" variant="light">
            {EVALUATION_EVENT_RATING_SCALE_NAME_MAP[evaluationCriteriaItem.ratingScale]}
          </Text>
          {isRatingScaleVisible && (
            <Button className="ml-2" onClick={() => handleTabChange('scale-definitions')} state="text" variant="link">
              View definitions
            </Button>
          )}
        </div>

        {evaluationCriteriaItem.supplementary && (
          <>
            <Text className="font-medium mt-4">Scoring requirements</Text>
            <ViewMoreContainer>
              <Text className="font-medium whitespace-pre-line" variant="light">
                {evaluationCriteriaItem.supplementary}
              </Text>
            </ViewMoreContainer>
          </>
        )}
      </div>
      <Tabs<Tab>
        className="border-b border-gray-300 w-full mt-2 mb-4"
        tab={tab}
        tabs={tabs}
        onChange={({ id }) => handleTabChange(id)}
        variant="underline"
      />
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={tab} transition={transition}>
          {tab === 'score' && (
            <div className="w-3/4">
              <Label>Score</Label>
              <EvaluationEventRatingScaleValueField
                ratingScale={evaluationCriteriaItem.ratingScale}
                value={evaluationScore?.value?.toString()}
                onSubmit={async (value) => await handleScoreSubmit({ value, evaluationScore })}
                isDisabled={isDisabled || isSaving}
              />
              <Label className="mt-6">Comment</Label>
              <AsyncTextArea
                className="w-full"
                value={comment || ''}
                onChange={({ target }) => setComment(target.value)}
                onSubmit={async () => await handleCommentSubmit({ comment, evaluationScore })}
                rows={6}
                placeholder="--"
                isDisabled={isDisabled || isSaving}
              />
            </div>
          )}
          {tab === 'scale-definitions' && <EvaluationEventRatingScaleDefinitionList ratingScale={evaluationCriteriaItem.ratingScale} />}
          {tab === 'compare-scores' && evaluation && (
            <EvaluationEventCriteriaScoreComparisonList evaluation={evaluation} evaluationCriteriaItem={evaluationCriteriaItem} />
          )}
        </TransitionContainer>
      </AnimatePresence>
    </>
  )
})
