import React, { FormEvent, memo, useMemo, useState } from 'react'
import { useListUser, userService } from '@cotiss/user'
import {
  AttachmentSignatureType,
  ContractDocumentAttachmentSignature,
  UpdateAttachmentSignaturesBulkBody,
  contractService,
  useGetContractShell,
  useMutateContractShell,
} from '@cotiss/contract'
import {
  useCallout,
  Form,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Label,
  sentryService,
  useToast,
  MultiSelect,
  Input,
  Select,
  Skeleton,
  SelectOption,
} from '@cotiss/common'
import { find } from 'lodash'

type SignatureFormData = {
  name: string
  email: string
  signatureType: AttachmentSignatureType
}

type Props = {
  existingSignatures: ContractDocumentAttachmentSignature[]
  signature?: ContractDocumentAttachmentSignature
  contractShellId: string
}

const SIGNATURE_TYPE_OPTIONS: SelectOption<AttachmentSignatureType>[] = [
  { value: 'EXTERNAL', label: 'External' },
  { value: 'INTERNAL', label: 'Internal' },
]

export const ContractSignatureModal = memo(({ existingSignatures, signature, contractShellId }: Props) => {
  const { closeModal } = useCallout()
  const { openToast } = useToast()
  const { contractShell } = useGetContractShell(contractShellId)
  const { users, isLoading: isUserListLoading } = useListUser()
  const { updateAttachmentSignaturesBulk } = useMutateContractShell()
  const [isSaving, setIsSaving] = useState(false)

  const [formData, setFormData] = useState<SignatureFormData>({
    name: signature?.name || '',
    email: signature?.email || '',
    signatureType: signature?.signatureType || 'INTERNAL',
  })

  const userOptions = useMemo(
    () =>
      users.map((user) => {
        return { label: userService.getFullName(user), value: user._id }
      }),
    [users]
  )

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const contract = contractShell ? contractService.getContract(contractShell, ['DRAFTING']) : null
    const documentShell = find(contract?.documentShells, { type: 'CONTRACT' })
    const attachment = documentShell?.attachments.length ? documentShell?.attachments[0] : null

    if (!contractShell || !contract || !documentShell || !attachment) {
      openToast('Something went wrong trying to save. Please try again.', 'danger')
      sentryService.captureException({
        exception: 'Tried to save contract signature but missing information',
        extras: { contractShellId: contractShell?._id },
      })
      return
    }

    let updatedSignatures: UpdateAttachmentSignaturesBulkBody[] = []

    // If we're adding a new signature
    if (!signature) {
      updatedSignatures = [...(existingSignatures || []), { attachment: attachment._id, ...formData }]
    }

    // If we're updating an existing signature
    if (signature) {
      const filteredExistingSignatures = existingSignatures.filter((_signature) => _signature._id !== signature._id)
      updatedSignatures = [...filteredExistingSignatures, { attachment: attachment._id, ...formData }]
    }

    try {
      setIsSaving(true)
      await updateAttachmentSignaturesBulk({
        contractShellId: contractShell._id,
        contractId: contract._id,
        documentShellId: documentShell._id,
        attachmentId: attachment?._id,
        body: { items: updatedSignatures },
      })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }

    setIsSaving(false)
    closeModal()
  }

  return (
    <Form className="w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${signature ? 'Edit row' : 'Add row'}`} isDisabled={isSaving} />
      <ModalContent>
        {!formData.email && !formData.name && (
          <>
            <Label>Name</Label>
            {isUserListLoading && <Skeleton className="h-9 w-full" />}
            {!isUserListLoading && (
              <MultiSelect
                options={userOptions}
                isDisabled={isSaving}
                onChange={(value) => {
                  const user = users.find((user) => user._id === value[0].value)
                  setFormData({ ...formData, email: user?.email || '', name: userService.getFullName(user) })
                }}
                onCreate={(name) => {
                  setFormData({ ...formData, name })
                }}
              />
            )}
          </>
        )}
        {(formData.email || formData.name) && (
          <>
            <Label>Name</Label>
            <Input
              value={formData.name}
              placeholder="Enter full name"
              onChange={({ target }) => setFormData({ ...formData, name: target.value })}
              isDisabled={isSaving}
              isRequired
            />
            <Label className="mt-4">Email</Label>
            <Input
              value={formData.email}
              placeholder="Enter email address"
              onChange={({ target }) => setFormData({ ...formData, email: target.value })}
              isDisabled={isSaving}
              isRequired
              type="email"
            />
            <Label className="mt-4">Role</Label>
            <Select
              isDisabled={isSaving}
              value={formData.signatureType}
              options={SIGNATURE_TYPE_OPTIONS}
              onChange={(value) => setFormData({ ...formData, signatureType: value })}
            />
          </>
        )}
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
