import { compact, forEach, map, uniq } from 'lodash'
import React, { FormEvent, memo, useMemo, useState } from 'react'
import { PreferredSupplierPopulatedModel, useListPreferredSupplierTag, useMutatePreferredSupplier } from '@cotiss/preferred-supplier'
import {
  Form,
  Label,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useCallout,
  MultiSelect,
  MultiSelectOption,
  sentryService,
  useToast,
} from '@cotiss/common'

type Props = {
  preferredSuppliers: PreferredSupplierPopulatedModel[]
  onClose?: () => void | Promise<void>
}

export const PreferredSupplierAddTagsModal = memo(({ preferredSuppliers, onClose }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [q, setQ] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const { preferredSupplierTags } = useListPreferredSupplierTag({ q })
  const { updatePreferredSupplierBulk } = useMutatePreferredSupplier()
  const existingTags = useMemo(() => {
    let existingTags: string[] = []

    forEach(compact(map(preferredSuppliers, (preferredSupplier) => preferredSupplier.tags)), (tags) => {
      existingTags = [...existingTags, ...tags]
    })

    return map(compact(uniq(existingTags)), (tag) => ({ label: tag, value: tag }))
  }, [preferredSupplierTags])
  const [tags, setTags] = useState<MultiSelectOption[]>(existingTags)

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)

      await updatePreferredSupplierBulk({
        preferredSupplierIds: map(preferredSuppliers, ({ _id }) => _id),
        updates: { tags: map(tags, ({ value }) => value) },
      })

      if (onClose) {
        await onClose()
      }

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Add tags" isDisabled={isSaving} />
      <ModalContent>
        <Label>Tags</Label>
        <MultiSelect
          value={tags}
          options={map(preferredSupplierTags, (tag) => ({ label: tag, value: tag }))}
          onInputChange={setQ}
          onChange={(tags) => setTags(tags)}
          onCreate={(value) => setTags([...tags, { value, label: value }])}
          isDisabled={isSaving}
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
