import React, { memo, useMemo, useState } from 'react'
import { filter, forEach, includes, map } from 'lodash'
import { DocumentListItem, DocumentModel } from '@cotiss/document'
import {
  Button,
  Table_DEPRECATED,
  Thead_DEPRECATED,
  Tbody_DEPRECATED,
  Th_DEPRECATED,
  Checkbox,
  useSortTable,
  sortService,
  Text,
  routerService,
  NoDataPlaceholder,
  ListItemsSkeleton,
} from '@cotiss/common'

type Props = {
  className?: string
  documents?: DocumentModel[]
  title?: string
  onBulkRemove?: (documentIdsToRemove: string[]) => void | Promise<void>
  onUpload?: () => void
  isLoading?: boolean
  isDisabled?: boolean
}

type SortKey = 'filename' | 'size' | 'date'

export const DocumentList = memo(({ className, documents = [], title, onBulkRemove, onUpload, isLoading, isDisabled }: Props) => {
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([])
  const { isAllSelected, isSomeSelected } = useMemo(() => {
    const isAllSelected = documents.length === selectedDocumentIds.length
    const isSomeSelected = Boolean(!isAllSelected && selectedDocumentIds.length)

    return { isAllSelected, isSomeSelected }
  }, [selectedDocumentIds, documents])
  const { sortKey, sortDirection, onSort } = useSortTable<SortKey>({ initialKey: 'filename' })
  const sortedDocuments = useMemo(() => {
    const result = documents.sort((a, b) => {
      if (sortKey === 'filename') {
        return sortService.sortString(a.fileName, b.fileName)
      } else if (sortKey === 'size') {
        return sortService.sortNumber(a.fileSize, b.fileSize)
      } else if (sortKey === 'date') {
        return sortService.sortDate(a.createdAt, b.createdAt)
      }

      return 0
    })

    return sortDirection === 'asc' ? result : result.reverse()
  }, [documents, sortKey, sortDirection])

  const handleSort = (sortKey: SortKey) => {
    setSelectedDocumentIds([])
    onSort(sortKey)
  }

  const handleAllCheckboxChange = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedDocumentIds(map(sortedDocuments, (document) => document._id))
    } else {
      setSelectedDocumentIds([])
    }
  }

  const handleCheckboxChange = (documentId?: string) => {
    if (!documentId) {
      return
    }

    setSelectedDocumentIds(
      includes(selectedDocumentIds, documentId) ? filter(selectedDocumentIds, (id) => id !== documentId) : [...selectedDocumentIds, documentId]
    )
  }

  const handleBulkDownload = () => {
    forEach(selectedDocumentIds, (id) => {
      window.open(routerService.getHref('/document/view/:id', { id }), '_blank')
    })
  }

  const handleBulkRemove = async () => {
    if (onBulkRemove) {
      await onBulkRemove(selectedDocumentIds)
      setSelectedDocumentIds([])
    }
  }

  const renderTableHeader = () => (
    <Thead_DEPRECATED className="bg-secondary-100 rounded-md">
      <Th_DEPRECATED className="rounded-l-md w-4">
        <Checkbox
          onChange={() => handleAllCheckboxChange(!isAllSelected)}
          isChecked={isAllSelected}
          isIndeterminate={isSomeSelected}
          isDisabled={isDisabled || isLoading}
        />
      </Th_DEPRECATED>
      <Th_DEPRECATED onSort={() => handleSort('filename')}>File name</Th_DEPRECATED>
      <Th_DEPRECATED className="w-16" onSort={() => handleSort('size')}>
        Size
      </Th_DEPRECATED>
      <Th_DEPRECATED className="text-right rounded-r-md w-28" onSort={() => handleSort('date')}>
        Uploaded
      </Th_DEPRECATED>
    </Thead_DEPRECATED>
  )

  return (
    <div className={className}>
      <div className="flex items-center justify-between mb-2">
        <Text className="ml-2" variant="heading" font="jakarta">
          {title || 'Files'}
          <Text className="text-light" isInline>
            {' '}
            ({documents.length})
          </Text>
        </Text>
        <div className="flex items-center whitespace-nowrap">
          {Boolean(selectedDocumentIds.length) && (
            <>
              {onBulkRemove && (
                <Button onClick={handleBulkRemove} size="sm" state="translucent" variant="secondary" isDisabled={isDisabled}>
                  Remove
                </Button>
              )}
              <Button className="ml-2" onClick={handleBulkDownload} size="sm" state="translucent" variant="secondary" isDisabled={isDisabled}>
                Download
              </Button>
            </>
          )}
          {onUpload && (
            <Button className="ml-2" onClick={onUpload} variant="secondary" size="sm" isDisabled={isDisabled}>
              + Upload documents
            </Button>
          )}
        </div>
      </div>
      {!isLoading && !documents.length && <NoDataPlaceholder label="No attachments uploaded" ctaLabel="+ Upload documents" onCtaClick={onUpload} />}

      {(isLoading || Boolean(documents.length)) && (
        <Table_DEPRECATED isSeparate>
          {renderTableHeader()}
          <Tbody_DEPRECATED>
            {isLoading && <ListItemsSkeleton columns={4} variant="default" />}
            {!isLoading && (
              <>
                {map(sortedDocuments, (document, index) => (
                  <DocumentListItem
                    key={index}
                    document={document}
                    onCheckboxChange={handleCheckboxChange}
                    isSelected={includes(selectedDocumentIds, document._id)}
                    isDisabled={isDisabled}
                  />
                ))}
              </>
            )}
          </Tbody_DEPRECATED>
        </Table_DEPRECATED>
      )}
    </div>
  )
})
