import { flatMap, map, sortBy, uniqBy } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { UserAvatarGroup, useUserAccess } from '@cotiss/user'
import { useApprovalTemplate } from '@cotiss/approval-template'
import { useApprovalTemplateGroup } from '@cotiss/approval-template-group'
import { SettingsModulesApprovalTemplateGroupCreateModal, SettingsModulesApprovalTemplateGroupUpdateDrawer } from '@cotiss/settings'
import {
  Badge,
  Button,
  ConfirmModal,
  Icon,
  ScrollableTable,
  ScrollableTableColumn,
  TableHeader,
  TableRowCta,
  Text,
  sentryService,
  useAsyncEffect,
  useCallout,
  useToast,
} from '@cotiss/common'

const SETTINGS_APPROVAL_TEMPLATE_GROUP_QUERY_PAGE_SIZE = 20

export const SettingsModulesContractApprovalsTab = memo(() => {
  const { openToast } = useToast()
  const { permissions } = useUserAccess()
  const { openModal, openDrawer } = useCallout()
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<GqlPagination>()
  const [isTemplatesLoading, setIsTemplatesLoading] = useState(false)
  const { mutateDeleteApprovalTemplatesByApprovalTemplateGroupId } = useApprovalTemplate()
  const { approvalTemplateGroups, queryApprovalTemplateGroupList, mutateDeleteApprovalTemplateGroup } = useApprovalTemplateGroup()

  const handleQueryApprovalTemplateGroupList = async () => {
    return await queryApprovalTemplateGroupList({
      filter: { module: 'contract' },
      pagination: { page: currentPage, pageSize: SETTINGS_APPROVAL_TEMPLATE_GROUP_QUERY_PAGE_SIZE },
    })
  }

  useAsyncEffect(async () => {
    try {
      setIsTemplatesLoading(true)
      const { pagination } = await handleQueryApprovalTemplateGroupList()
      setPagination(pagination)
      setIsTemplatesLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsTemplatesLoading(false)
    }
  }, [currentPage])

  const handleDeleteApprovalTemplateGroup = async (approvalTemplateGroupId: string) => {
    try {
      // 1. Delete the related approval templates.
      // This is done in the front end instead of the back end because
      // approval template groups are specific to the contract module and
      // we don't want this as the default behavior for all modules.
      await mutateDeleteApprovalTemplatesByApprovalTemplateGroupId({ approvalTemplateGroupId })

      // 2. Delete the approval template group
      await mutateDeleteApprovalTemplateGroup({ approvalTemplateGroupId })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Name',
        rows: map(approvalTemplateGroups, (approvalTemplateGroup) => ({
          content: () => (
            <Text className="truncate" font="jakarta" title={approvalTemplateGroup.name || '--'}>
              {approvalTemplateGroup.name || '--'}
            </Text>
          ),
          cta: (
            <TableRowCta
              cta={{
                label: permissions.isAdmin ? 'Edit' : 'View',
                onClick: () =>
                  openDrawer(
                    <SettingsModulesApprovalTemplateGroupUpdateDrawer
                      approvalTemplateGroupId={approvalTemplateGroup.id}
                      module="contract"
                      isEditable={permissions.isAdmin}
                    />
                  ),
              }}
              actions={
                permissions.isAdmin
                  ? [
                      {
                        label: 'Delete',
                        onClick: () =>
                          openModal(
                            <ConfirmModal
                              heading="Delete approval sequence"
                              description="Are you sure you want to delete this approval sequence?"
                              onSubmit={async () => await handleDeleteApprovalTemplateGroup(approvalTemplateGroup.id)}
                            />
                          ),
                      },
                    ]
                  : undefined
              }
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Description',
        rows: map(approvalTemplateGroups, (approvalTemplateGroup) => ({
          content: () => (
            <Text className="whitespace-pre-wrap" size="sm">
              {approvalTemplateGroup.description || '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Users',
        rows: map(approvalTemplateGroups, (approvalTemplateGroup) => ({
          content: () => (
            <UserAvatarGroup size="sm" users={uniqBy(map(flatMap(approvalTemplateGroup.approvalTemplates, 'approvalTemplateUsers'), 'user'), 'id')} />
          ),
        })),
      },
      {
        heading: 'Sequence',
        rows: map(approvalTemplateGroups, (approvalTemplateGroup) => ({
          content: () => (
            <div className="flex">
              {map(sortBy(approvalTemplateGroup.approvalTemplates, 'order'), (approvalTemplate) => (
                <Badge key={approvalTemplate.id} className="mr-2" state="translucent" shape="square" variant="neutral" title={approvalTemplate.name}>
                  {approvalTemplate.name}
                </Badge>
              ))}
            </div>
          ),
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [approvalTemplateGroups, permissions])

  return (
    <>
      <TableHeader className="flex justify-between items-center">
        <div>
          <Text className="font-semibold" font="jakarta">
            Approval sequences
          </Text>
          <Text variant="light" size="sm" className="mt-1">
            Create and assign users to custom approval roles.
          </Text>
        </div>
        {permissions.isAdmin && (
          <Button size="xs" variant="secondary" onClick={() => openModal(<SettingsModulesApprovalTemplateGroupCreateModal module="contract" />)}>
            <Icon icon="plus" /> Add sequence
          </Button>
        )}
      </TableHeader>
      <ScrollableTable
        isLoading={isTemplatesLoading}
        fixedColumns={fixedColumns}
        columns={columns}
        pagination={pagination}
        onPageChange={setCurrentPage}
        emptyCta={
          permissions.isAdmin && (
            <Button
              size="sm"
              state="text"
              variant="secondary"
              onClick={() => openModal(<SettingsModulesApprovalTemplateGroupCreateModal module="contract" />)}
            >
              + Add sequence
            </Button>
          )
        }
      />
    </>
  )
})
