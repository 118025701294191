import { find, map } from 'lodash'
import React, { memo, useMemo } from 'react'
import CreatableSelect from 'react-select/creatable'
import { ProjectModel } from '@cotiss/project'
import { MultiSelectOption } from '@cotiss/common'

type Props = {
  className?: string
  value?: string
  options: ProjectModel[]
  onChange: (value: string) => void
  onCreate: (value: string) => void
  onClear: () => void
  isRequired?: boolean
  isDisabled?: boolean
}

export const ProjectSelect = memo(({ className, value, options, onChange, onCreate, onClear, isRequired, isDisabled }: Props) => {
  const { projectValue, projectOptions } = useMemo(() => {
    const projectOptions = map(options, (project) => ({
      value: project._id,
      label: project.title,
    }))
    const projectValue = (find(projectOptions, { value }) || {
      label: value,
      value,
    }) as MultiSelectOption

    return { projectValue, projectOptions }
  }, [value, options])

  const handleChange = (projectValue?: MultiSelectOption) => {
    if (projectValue) {
      onChange(projectValue.value)
    } else {
      onClear()
    }
  }

  const handleCreate = (value: string) => {
    onChange(value)
    onCreate(value)
  }

  return (
    <div className="relative">
      <CreatableSelect
        className={className}
        value={value ? projectValue : undefined}
        options={projectOptions}
        onChange={(projectValue) => handleChange(projectValue as MultiSelectOption)}
        onCreateOption={handleCreate}
        placeholder="Search or create project title..."
        noOptionsMessage={() => 'Type to create project title...'}
        required={isRequired}
        isDisabled={isDisabled}
        isClearable={!isDisabled}
        components={{ IndicatorSeparator: null }}
      />
    </div>
  )
})
