import { findIndex, includes, some } from 'lodash'
import { AnimatePresence } from 'framer-motion'
import { useHistory, useParams } from 'react-router-dom'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { AppErrorPage } from '@cotiss/app'
import {
  PerformanceScorecardStatusBadge,
  PerformanceScorecardViewMetricsTab,
  PerformanceScorecardViewResourcesTab,
  PerformanceScorecardViewTeamTab,
  PerformanceScorecardViewTrackingTab,
  usePerformanceScorecard,
  usePerformanceScorecardAnalytics,
  usePerformanceScorecardMetric,
  usePerformanceScorecardUser,
} from '@cotiss/performance'
import {
  Breadcrumb,
  BreadcrumbModel,
  Button,
  ConfirmModal,
  FourOhFourPage,
  FourOhThreePage,
  Header,
  Page,
  PageContent,
  routerService,
  sentryService,
  Skeleton,
  TabModel,
  Tabs,
  Text,
  TransitionContainer,
  useAsyncEffect,
  useCallout,
  useFeature,
  useToast,
  useTransition,
} from '@cotiss/common'

export type PerformanceScorecardViewTab = 'tracking' | 'metrics' | 'team' | 'resources'

export const PerformanceScorecardViewPage = memo(() => {
  const { openToast } = useToast()
  const { openModal } = useCallout()
  const { push, replace } = useHistory()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { track } = usePerformanceScorecardAnalytics()
  const isPerformanceScorecardEnabled = useFeature('performance-scorecard')
  const { step, transition, onTransition } = useTransition({ initialStep: 2 })
  const { performanceScorecardMetrics, queryPerformanceScorecardMetricList } = usePerformanceScorecardMetric()
  const { performanceScorecardUserInSession, queryPerformanceScorecardUserInSessionView } = usePerformanceScorecardUser()
  const { performanceScorecardId, tab } = useParams<{ performanceScorecardId: string; tab?: PerformanceScorecardViewTab }>()
  const { performanceScorecard, queryPerformanceScorecardView, mutatePublishPerformanceScorecard } = usePerformanceScorecard()

  const tabs: TabModel<PerformanceScorecardViewTab>[] = [
    {
      id: 'tracking',
      label: 'Tracking',
      isDisabled: performanceScorecard?.status !== 'active' ? 'Tracking tab will be unlocked once scorecard is published.' : '',
    },
    { id: 'metrics', label: 'Metrics' },
    { id: 'team', label: 'Team' },
    { id: 'resources', label: 'Resources' },
  ]

  const backHref = routerService.getHref('/performance/scorecard/list/:tab?')
  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Performance',
      href: backHref,
    },
    {
      label: performanceScorecard?.name || '',
      isLoading,
    },
  ]

  const { isOwner } = useMemo(() => {
    const isOwner = includes(performanceScorecardUserInSession?.roles, 'owner')

    return { isOwner }
  }, [performanceScorecardUserInSession])

  useAsyncEffect(async () => {
    try {
      const [performanceScorecard, performanceScorecardUserInSession] = await Promise.all([
        queryPerformanceScorecardView({ performanceScorecardId }),
        queryPerformanceScorecardUserInSessionView({ performanceScorecardId }),
        queryPerformanceScorecardMetricList({ filter: { performanceScorecardId } }),
      ])

      track('performance_scorecard_view')

      if (performanceScorecard.status === 'draft' && !includes(performanceScorecardUserInSession.roles, 'owner')) {
        openToast('You do not have permission to view this draft performance scorecard.', 'danger')
        replace(backHref)
        return
      }

      if (!tab || !some(tabs, { id: tab })) {
        push(
          routerService.getHref('/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?', {
            performanceScorecardId,
            tab: performanceScorecard.status === 'active' ? 'tracking' : 'metrics',
          })
        )
      }
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!performanceScorecard) {
      return
    }

    if (performanceScorecard.isArchived) {
      openToast('You cannot access an archived performance scorecard.', 'danger')
      return push(routerService.getHref('/performance/scorecard/list/:tab?'))
    }
  }, [performanceScorecard])

  useEffect(() => {
    if (isLoading) {
      return
    }

    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    }
  }, [isLoading, tab])

  if (!isLoading && isError) {
    return <AppErrorPage />
  }

  if (!isLoading && !performanceScorecard) {
    return <FourOhFourPage />
  }

  if (!isPerformanceScorecardEnabled) {
    return <FourOhThreePage />
  }

  const handlePublishPerformanceScorecard = async () => {
    await mutatePublishPerformanceScorecard({ performanceScorecardId })
    await queryPerformanceScorecardView({ performanceScorecardId })
    push(routerService.getHref('/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?', { performanceScorecardId, tab: 'tracking' }))
  }

  return (
    <Page>
      <Header>
        <div className="flex items-start justify-between">
          <div>
            <Breadcrumb className="mb-1" backHref={backHref} breadcrumbs={breadcrumbs} isDisabled={isLoading} />
            {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
            {!isLoading && performanceScorecard && (
              <Text className="font-medium flex items-center" size="h7" variant="heading" font="jakarta">
                {performanceScorecard.name}
                <PerformanceScorecardStatusBadge className="ml-2" status={performanceScorecard.status} state="translucent" size="sm" />
              </Text>
            )}
            <div className="flex items-center mt-1">
              {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
              {!isLoading && performanceScorecard && (
                <>
                  <Text className="mr-1" variant="light" size="sm">
                    Vendor:
                  </Text>
                  <Button
                    href={routerService.getHref('/supplier/view/:id/:tab?', { id: performanceScorecard.contact.id })}
                    size="sm"
                    state="text"
                    variant="link"
                    isLink
                  >
                    {performanceScorecard.contact.name}
                  </Button>
                </>
              )}
            </div>
          </div>
          {isOwner && performanceScorecard?.status === 'draft' && (
            <Button
              className="ml-4"
              onClick={() =>
                openModal(
                  <ConfirmModal
                    heading="Are you sure you want to publish this performance scorecard?"
                    description="Once the scorecard is published, metrics can only be archived and not permanently removed."
                    onSubmit={handlePublishPerformanceScorecard}
                  />
                )
              }
              size="sm"
              isDisabled={!performanceScorecardMetrics.length}
            >
              Publish
            </Button>
          )}
        </div>
      </Header>
      <div className="sticky top-[84px] bg-white border-t border-gray-100 shadow-sm px-10 z-30">
        {!isLoading && (
          <Tabs<PerformanceScorecardViewTab>
            className="w-full"
            tab={tab}
            tabs={tabs}
            onChange={({ id: tab }) =>
              push(routerService.getHref('/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?', { performanceScorecardId, tab }))
            }
            variant="underline"
          />
        )}
      </div>
      <PageContent>
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {step === 1 && <PerformanceScorecardViewTrackingTab />}
            {step === 2 && <PerformanceScorecardViewMetricsTab />}
            {step === 3 && <PerformanceScorecardViewTeamTab />}
            {step === 4 && <PerformanceScorecardViewResourcesTab />}
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
