import classNames from 'classnames'
import { parseISO } from 'date-fns'
import React, { memo, useEffect, useState } from 'react'
import { DocumentModel, documentService, useGetDocument } from '@cotiss/document'
import { Button, Td_DEPRECATED, Tr_DEPRECATED, Checkbox, Icon, datetimeService, Text } from '@cotiss/common'

type Props = {
  className?: string
  document: DocumentModel
  onCheckboxChange: (documentId?: string) => void
  isSelected?: boolean
  isDisabled?: boolean
}

export const DocumentListItem = memo(({ className, document, onCheckboxChange, isSelected, isDisabled }: Props) => {
  const [documentIdToDownload, setDocumentIdToDownload] = useState('')
  const { document: documentToDownload } = useGetDocument(documentIdToDownload)
  const buttonClasses = classNames('text-sm underline cursor-pointer truncate inline-block align-middle', {
    'text-secondary-500 hover:text-primary-800 visited:text-primary-800': !isDisabled,
    'text-gray-400 pointer-events-none': isDisabled,
  })

  useEffect(() => {
    if (documentToDownload?.downloadUrl) {
      window.open(documentToDownload.downloadUrl)
      setDocumentIdToDownload('')
    }
  }, [documentToDownload])

  return (
    <Tr_DEPRECATED className={className}>
      <Td_DEPRECATED>
        <Checkbox onChange={() => onCheckboxChange(document._id)} isChecked={isSelected} isDisabled={isDisabled} />
      </Td_DEPRECATED>
      <Td_DEPRECATED className="text-sm rounded-l-md">
        <div className="flex items-center">
          <div className="inline-flex items-center justify-center bg-primary-200 rounded w-7 h-7 mr-4">
            <Icon icon="file-04" variant="link" />
          </div>
          <Button className={buttonClasses} onClick={() => setDocumentIdToDownload(document._id)} state="raw" isDisabled={isDisabled}>
            <Text>{document.fileName}</Text>
          </Button>
        </div>
      </Td_DEPRECATED>
      <Td_DEPRECATED>
        <Text size="sm" variant="light">
          {documentService.formatSize(document.fileSize)}
        </Text>
      </Td_DEPRECATED>
      <Td_DEPRECATED className="rounded-r-md">
        <Text size="sm" variant="light">
          {datetimeService.format(parseISO(document.createdAt), 'do MMM yyyy')}
        </Text>
      </Td_DEPRECATED>
    </Tr_DEPRECATED>
  )
})
