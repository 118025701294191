import { useParams } from 'react-router-dom'
import React, { memo, useMemo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { filter, findIndex, includes } from 'lodash'
import { tenderFlowService } from '@cotiss/tender-flow'
import { useListTenderCriteria } from '@cotiss/tender-criteria'
import { Text, TransitionContainer, VerticalTabModel, VerticalTabs, useTransition } from '@cotiss/common'
import {
  TenderPlanApprovalsTab,
  TenderPlanEvaluationTab,
  TenderPlanMethodologyTab,
  TenderPlanOverviewTab,
  TenderRequestDetailsTab,
  TenderRequestDocumentsTab,
  TenderRequestPricingTableTab,
  TenderRequestQuestionsTab,
  TenderRequestResponseFormsTab,
  tenderService,
  useGetTender,
  useTenderValidationSummary,
} from '@cotiss/tender'

export type TenderRequestTab =
  | 'details'
  | 'questions'
  | 'documents'
  | 'response-forms'
  | 'pricing-table'
  | 'metafields'
  | 'overview'
  | 'summary'
  | 'evaluation'
  | 'methodology'
  | 'approvals'

type Props = {
  tenderId: string
  onTabChange: (tab: TenderRequestTab) => void
  isEditable?: boolean
  isOverviewVisible?: boolean
}

export const TenderRequest = memo(({ tenderId, onTabChange, isEditable, isOverviewVisible }: Props) => {
  const { tab } = useParams<{ tab?: TenderRequestTab }>()
  const { tender } = useGetTender(tenderId)
  const { tenderCriteria } = useListTenderCriteria({ tenderId })
  const validation = useTenderValidationSummary(tender?.validation)
  const isOldEvaluationsEnabled = tenderFlowService.isOldEvaluationsEnabled(tender)

  const tabs = useMemo(() => {
    const tabs: VerticalTabModel<TenderRequestTab>[] = [
      { id: 'overview', label: 'Overview', tasksRemaining: validation.planningSummaryTasks.length, isHidden: !isOverviewVisible },
      {
        id: 'details',
        label: 'Details',
        tasksRemaining: validation.plannedDetailsTasks.length,
      },
      {
        id: 'documents',
        label: 'Documents',
        tasksRemaining: validation.plannedDocumentTasks.length,
      },
      {
        id: 'response-forms',
        label: 'Response forms',
        tasksRemaining: validation.plannedNonPriceDocumentTasks.length,
      },
      {
        id: 'pricing-table',
        label: 'Pricing table',
        tasksRemaining: validation.plannedPriceDocumentTasks.length,
        isHidden: !isOldEvaluationsEnabled,
      },
      {
        id: 'evaluation',
        label: 'Evaluation',
        tasksRemaining: validation.planningEvaluationTasks.length,
        isHidden: !isOldEvaluationsEnabled,
      },
      {
        id: 'methodology',
        label: 'Methodology',
        tasksRemaining: validation.planningMethodologyTasks.length,
        isLocked: !tenderService.hasEvaluation(tender),
        isHidden: !isOldEvaluationsEnabled,
      },
      {
        id: 'questions',
        label: 'Sub-criteria',
        tasksRemaining: validation.plannedQuestionTasks.length,
        isLocked: !tenderService.hasEvaluation(tender) || !tenderCriteria.length,
        isHidden: !isOldEvaluationsEnabled,
      },
      {
        id: 'approvals',
        label: 'Approvals',
        tasksRemaining: validation.planningApprovalTasks.length,
      },
    ]

    return filter(tabs, (tab) => !tab.isHidden)
  }, [tender, validation])

  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(tabs, ({ id }) => id === tab) + 1 })

  const activeTabId = tabs[step - 1].id

  const handleTabChange = (tab: TenderRequestTab) => {
    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1

    onTransition({ step: newStep, transition: newStep > step ? 'bottom' : 'top' })
    onTabChange(tab)
  }

  return (
    <div className="flex items-start justify-between">
      <div className="sticky top-28 mr-8">
        <Text className="uppercase mb-2" variant="light" size="xs">
          Listing
        </Text>

        <VerticalTabs<TenderRequestTab>
          tab={tab}
          tabs={filter(tabs, (tab) => includes(['overview', 'details', 'documents', 'response-forms', 'pricing-table', 'metafields'], tab.id))}
          onChange={({ id }) => handleTabChange(id)}
        />
        <Text className="uppercase mb-2 mt-4" variant="light" size="xs">
          General
        </Text>

        <VerticalTabs<TenderRequestTab>
          tab={tab}
          tabs={filter(tabs, (tab) => includes(['evaluation', 'methodology', 'questions', 'approvals'], tab.id))}
          onChange={({ id }) => handleTabChange(id)}
        />
      </div>
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} className="min-w-0 w-full" transition={transition}>
          {activeTabId === 'overview' && <TenderPlanOverviewTab tenderId={tenderId} />}
          {activeTabId === 'details' && <TenderRequestDetailsTab tenderId={tenderId} isEditable={isEditable} />}
          {activeTabId === 'documents' && <TenderRequestDocumentsTab tenderId={tenderId} isEditable={isEditable} />}
          {activeTabId === 'response-forms' && <TenderRequestResponseFormsTab tenderId={tenderId} isEditable={isEditable} />}
          {activeTabId === 'pricing-table' && <TenderRequestPricingTableTab tenderId={tenderId} isEditable={isEditable} />}
          {activeTabId === 'evaluation' && <TenderPlanEvaluationTab tenderId={tenderId} isEditable={isEditable} />}
          {activeTabId === 'methodology' && <TenderPlanMethodologyTab tenderId={tenderId} isEditable={isEditable} />}
          {activeTabId === 'questions' && <TenderRequestQuestionsTab tenderId={tenderId} isEditable={isEditable} />}
          {activeTabId === 'approvals' && <TenderPlanApprovalsTab tenderId={tenderId} isEditable={isEditable} />}
        </TransitionContainer>
      </AnimatePresence>
    </div>
  )
})
