import { includes } from 'lodash'
import React, { FormEvent, useState, memo } from 'react'
import { TenderPopulatedModel, useMutateTender } from '@cotiss/tender'
import {
  Form,
  Input,
  Label,
  ModalContent,
  ModalFooter,
  ModalHeader,
  OCDS_CURRENCY_OPTIONS,
  OcdsCurrencyCode,
  Select,
  sentryService,
  Text,
  useCallout,
  useToast,
} from '@cotiss/common'

type Props = {
  tender: TenderPopulatedModel
  field: FieldName
  label: string
  supplementary?: string
}

type FormData = {
  currency?: OcdsCurrencyCode
  npv?: string
}

type FieldName = keyof FormData

export const TenderRequestPriceTableEditModal = memo(({ tender, field, label, supplementary }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { updateTender } = useMutateTender()
  const [isSaving, setIsSaving] = useState(false)
  const [formData, setFormData] = useState<FormData>({
    currency: tender.currency,
    npv: tender.npv ? tender.npv.toString() : '0',
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)

      if (includes(['currency', 'npv'], field)) {
        await updateTender(tender._id, {
          ...(field === 'currency' && { currency: formData.currency }),
          ...(field === 'npv' && { npv: Number(formData.npv) }),
        })
      }

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`Edit ${label.toLowerCase()}`} isDisabled={isSaving} />
      <ModalContent>
        <Label>{label}</Label>
        {field === 'currency' && (
          <Select<OcdsCurrencyCode>
            value={formData.currency}
            options={OCDS_CURRENCY_OPTIONS}
            onChange={(currency) => setFormData((prev) => ({ ...prev, currency: currency }))}
            placeholder
          />
        )}
        {field === 'npv' && (
          <div className="relative w-28">
            <div className="absolute top-2.5 right-[0.75rem] text-grey-500 text-base pointer-events-none">%</div>
            <Input
              className="pr-8"
              type="number"
              min={0}
              max={100}
              step=".01"
              value={formData.npv}
              onChange={({ target }) => setFormData({ ...formData, npv: target.value })}
              isDisabled={isSaving}
              isRequired
            />
          </div>
        )}
        {supplementary && (
          <Text className="mt-2" variant="light" size="sm">
            {supplementary}
          </Text>
        )}
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
