import { map } from 'lodash'
import React, { useState, memo, useMemo, useEffect } from 'react'
import { UserAvatarGroup } from '@cotiss/user'
import { NoDataPlaceholder, ScrollableTable, ScrollableTableColumn, Text, datetimeService, paginationService, useCallout } from '@cotiss/common'
import {
  ContractReminderAddModal,
  useGetContractShell,
  ContractReminderModel,
  ContractReminderListItemCta,
  ContractReminderStatusBadge,
  ContractReminderCountDown,
} from '@cotiss/contract'

type Props = {
  contractShellId: string
  isDisabled?: boolean
}

export const ContractReminderList = memo(({ contractShellId, isDisabled }: Props) => {
  const [reminders, setReminders] = useState<ContractReminderModel[] | undefined>([])
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    if (contractShell?.reminders) {
      setReminders(contractShell.reminders.filter((reminder) => !reminder.archived))
    }
  }, [contractShell])

  const { filteredReminders, pagination } = useMemo(() => {
    const { items: filteredReminders, pagination } = paginationService.paginate(reminders || [], { currentPage })

    return { filteredReminders, pagination }
  }, [reminders])

  const { openModal } = useCallout()

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Title',
      rows: map(filteredReminders, (reminder) => ({
        content: () => (
          <Text className="truncate" font="jakarta" title={reminder.title}>
            {reminder.title}
          </Text>
        ),
        cta: <ContractReminderListItemCta contractShellId={contractShellId} reminder={reminder} />,
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Due',
      rows: map(filteredReminders, ({ date }) => ({
        content: () => <Text size="sm">{datetimeService.format(date, 'do MMM yyyy')}</Text>,
      })),
    },

    {
      heading: 'Countdown',
      rows: map(filteredReminders, ({ date }) => ({
        content: () => <ContractReminderCountDown reminderDate={date} />,
      })),
    },
    {
      heading: 'Assigned',
      rows: map(filteredReminders, ({ users }) => ({
        content: () => <UserAvatarGroup users={users} size="xs" />,
      })),
    },
    {
      heading: 'Status',
      rows: map(filteredReminders, (reminder) => ({
        content: () => <ContractReminderStatusBadge reminder={reminder} />,
      })),
    },
    {
      heading: 'Description',
      rows: map(filteredReminders, ({ description }) => ({
        content: () => (
          <Text size="sm" className=" truncate max-w-xs" title={description}>
            {description}
          </Text>
        ),
      })),
    },
  ]

  return (
    <>
      {!filteredReminders?.length && !isLoading ? (
        <NoDataPlaceholder
          className="mt-4"
          label="No reminders"
          ctaLabel="+ Add reminder"
          isDisabled={isDisabled}
          onCtaClick={() => openModal(<ContractReminderAddModal contractShellId={contractShellId} />)}
        />
      ) : (
        <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
      )}
    </>
  )
})
