import { gql } from '@apollo/client'
import { apolloService } from '@cotiss/common'
import { PERFORMANCE_SCORECARD_METRIC_CYCLE_FRAGMENT } from '@cotiss/performance'
import {
  GqlUpdatePerformanceScorecardMetricCycleInput,
  GqlUpdatePerformanceScorecardMetricCycleMutation,
  GqlUpdatePerformanceScorecardMetricCycleMutationVariables,
  GqlPerformanceScorecardMetricCycleFieldsFragment,
} from '@gql'

export const mutateUpdatePerformanceScorecardMetricCycle = async (input: GqlUpdatePerformanceScorecardMetricCycleInput) => {
  const { data } = await apolloService.mutate<
    GqlUpdatePerformanceScorecardMetricCycleMutation,
    GqlUpdatePerformanceScorecardMetricCycleMutationVariables
  >({
    mutation: gql`
      ${PERFORMANCE_SCORECARD_METRIC_CYCLE_FRAGMENT}

      mutation UpdatePerformanceScorecardMetricCycle($input: UpdatePerformanceScorecardMetricCycleInput!) {
        updatePerformanceScorecardMetricCycle(input: $input) {
          ...PerformanceScorecardMetricCycleFields
        }
      }
    `,
    variables: { input },
  })

  return data?.updatePerformanceScorecardMetricCycle as GqlPerformanceScorecardMetricCycleFieldsFragment
}
