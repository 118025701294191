import { map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { userService, useUserAccess } from '@cotiss/user'
import { usePerformanceMetric } from '@cotiss/performance'
import { SettingsModulesPerformanceMetricCreateDrawer, SettingsModulesPerformanceMetricUpdateDrawer } from '@cotiss/settings'
import {
  Avatar,
  Button,
  Card,
  datetimeService,
  Hr,
  Icon,
  ScrollableTable,
  ScrollableTableColumn,
  sentryService,
  TableHeader,
  TableRowCta,
  Text,
  useAsyncEffect,
  useCallout,
  useFeature,
  useToast,
} from '@cotiss/common'

export const SettingsModulesPerformanceTab = memo(() => {
  const { openToast } = useToast()
  const { openDrawer } = useCallout()
  const { permissions } = useUserAccess()
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<GqlPagination>()
  const isPerformanceScorecardEnabled = useFeature('performance-scorecard')
  const { performanceMetrics, queryPerformanceMetricList } = usePerformanceMetric()

  const refreshPerformanceMetricList = async () => {
    const { pagination } = await queryPerformanceMetricList({ pagination: { page: currentPage, pageSize: 20 } })
    setPagination(pagination)
  }

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      await refreshPerformanceMetricList()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsLoading(false)
  }, [currentPage])

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Metric',
        rows: map(performanceMetrics, (performanceMetric) => ({
          content: () => (
            <Text className="truncate" font="jakarta" title={performanceMetric.name}>
              {performanceMetric.name}
            </Text>
          ),
          cta: (
            <TableRowCta
              cta={{
                label: (
                  <>
                    View <Icon className="ml-1" icon="arrow-right" />
                  </>
                ),
                onClick: () =>
                  openDrawer(
                    <SettingsModulesPerformanceMetricUpdateDrawer performanceMetric={performanceMetric} onSubmit={refreshPerformanceMetricList} />
                  ),
              }}
              actions={[
                {
                  label: 'Edit',
                  onClick: () =>
                    openDrawer(
                      <SettingsModulesPerformanceMetricUpdateDrawer
                        performanceMetric={performanceMetric}
                        onSubmit={refreshPerformanceMetricList}
                        isEditing
                      />
                    ),
                },
              ]}
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Group',
        rows: map(performanceMetrics, ({ group }) => ({
          content: () => <Text>{group}</Text>,
        })),
      },
      {
        heading: 'Description',
        isWrapped: true,
        rows: map(performanceMetrics, ({ description }) => ({
          content: () => <Text>{description}</Text>,
        })),
      },
      {
        heading: 'Created by',
        rows: map(performanceMetrics, ({ createdByUser }) => ({
          content: () => (createdByUser ? <Avatar size="sm">{userService.getInitials(createdByUser)}</Avatar> : '--'),
        })),
      },
      {
        heading: 'Created at',
        rows: map(performanceMetrics, ({ createdAt }) => ({
          content: () => <Text>{datetimeService.format(createdAt, 'do MMM yyyy')}</Text>,
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [performanceMetrics, permissions])

  if (!isPerformanceScorecardEnabled) {
    return null
  }

  return (
    <Card className="mb-8 flex-1">
      <Text className="font-medium" variant="heading" size="h5">
        Performance
      </Text>
      <Text className="mt-1" variant="light">
        Adjust settings specific to the performance module.
      </Text>
      <Hr className="my-4" />
      <TableHeader className="flex justify-between items-center">
        <div>
          <Text className="font-semibold" font="jakarta">
            Metrics
          </Text>
          <Text variant="light" size="sm" className="mt-1">
            Add metrics to build scorecards to measure vendor performance.
          </Text>
        </div>
        {permissions.isAdmin && (
          <Button
            onClick={() => openDrawer(<SettingsModulesPerformanceMetricCreateDrawer onSubmit={refreshPerformanceMetricList} />)}
            size="xs"
            variant="secondary"
          >
            <Icon icon="plus" /> Add metric
          </Button>
        )}
      </TableHeader>
      <ScrollableTable
        fixedColumns={fixedColumns}
        columns={columns}
        pagination={pagination}
        onPageChange={setCurrentPage}
        emptyCta={
          permissions.isAdmin && (
            <Button
              onClick={() => openDrawer(<SettingsModulesPerformanceMetricCreateDrawer onSubmit={refreshPerformanceMetricList} />)}
              size="sm"
              state="text"
              variant="secondary"
            >
              + Add metric
            </Button>
          )
        }
        isLoading={isLoading}
      />
    </Card>
  )
})
