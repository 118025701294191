import { map } from 'lodash'
import classNames from 'classnames'
import React, { memo } from 'react'
import { AvatarSize, AvatarSkeleton, SkeletonVariant } from '@cotiss/common'

type Props = {
  className?: string
  avatars?: number
  size?: AvatarSize
  variant?: SkeletonVariant
}

export const AvatarGroupSkeleton = memo(({ className, avatars = 3, size = 'md', variant }: Props) => {
  const classes = classNames(className, 'flex items-center')

  return (
    <div className={classes}>
      {map(Array(avatars), (_, index) => {
        const classes = classNames({
          '-ml-1.5': index,
        })

        return <AvatarSkeleton key={index} className={classes} size={size} variant={variant} />
      })}
    </div>
  )
})
