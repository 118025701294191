import React, { FormEvent, memo, useMemo, useState } from 'react'
import { filter, flatMap, map, toLower, uniq } from 'lodash'
import {
  useCallout,
  Form,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Input,
  Label,
  TextArea,
  Select,
  MetricUnit,
  sentryService,
  useToast,
  METRIC_UNIT_OPTIONS,
  Icon,
  useAnalytics,
} from '@cotiss/common'
import {
  ContractScheduledRateTagSelect,
  ScheduledRateModel,
  UpdateScheduledRatesBulkBody,
  contractService,
  useGetContractShell,
  useMutateContractShell,
} from '@cotiss/contract'

type ScheduledRatesFormData = {
  id: string
  title: string
  tag: string
  amount: string
  unit: MetricUnit
  description: string
}

type Props = {
  scheduledRate?: ScheduledRateModel
  contractShellId: string
}

export const ContractWizardScheduleRatesModal = memo(({ scheduledRate, contractShellId }: Props) => {
  const { closeModal } = useCallout()
  const { openToast } = useToast()
  const { contractShell } = useGetContractShell(contractShellId)
  const { updateScheduledRatesBulk } = useMutateContractShell()
  const { track } = useAnalytics()
  const [isSaving, setIsSaving] = useState(false)

  const { tagOptionsText, existingScheduledRates, contract } = useMemo(() => {
    if (!contractShell) {
      return {
        tagOptionsText: [],
        existingScheduledRates: [],
        contract: null,
      }
    }

    const contract = contractService.getContract(contractShell, ['DRAFTING'])

    const allTags: string[] = flatMap(contractShell?.contracts, ({ scheduledRates }) => map(scheduledRates, (sr) => sr.tag))
    const tagOptionsText = uniq(map(allTags, (tag) => toLower(tag)))

    return { tagOptionsText, existingScheduledRates: contract?.scheduledRates || [], contract }
  }, [contractShell])

  const [formData, setFormData] = useState<ScheduledRatesFormData>({
    id: scheduledRate?._id || crypto.randomUUID(),
    title: scheduledRate?.title || '',
    tag: scheduledRate?.tag || '',
    amount: scheduledRate?.amount.toString() || '',
    unit: scheduledRate?.unit || 'h',
    description: scheduledRate?.description || '',
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!contract) {
      // TODO: error
      return
    }

    const parsedScheduledRates: UpdateScheduledRatesBulkBody['items'] = []

    // If we're adding a new rate
    if (!existingScheduledRates?.find((rate) => rate._id === formData.id)) {
      track('contract_wizard_schedule_rates_create_submit')
      parsedScheduledRates.push(...existingScheduledRates, {
        ...formData,
        index: existingScheduledRates.length,
        tag: formData.tag.length ? formData.tag : '',
        amount: Number(formData.amount),
      })
    }

    const existingRate = existingScheduledRates?.find((rate) => rate._id === formData.id)

    // If we're updating an existing rate
    if (existingRate) {
      track('contract_wizard_schedule_rates_update_submit')
      parsedScheduledRates.push(...filter(existingScheduledRates, (rate) => rate._id !== formData.id), {
        ...formData,
        index: existingRate.index,
        tag: formData.tag.length ? formData.tag : '',
        amount: Number(formData.amount),
      })
    }

    try {
      setIsSaving(true)
      await updateScheduledRatesBulk(contractShellId, contract?._id, { items: parsedScheduledRates })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }

    setIsSaving(false)
    closeModal()
  }

  return (
    <Form className="w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${scheduledRate ? 'Edit row' : 'Add row'}`} isDisabled={isSaving} />
      <ModalContent>
        <Label>Item</Label>
        <Input
          value={formData.title}
          placeholder="Enter item"
          onChange={({ target }) => setFormData({ ...formData, title: target.value })}
          isDisabled={isSaving}
          isRequired
        />

        <Label className="mt-6">Tag</Label>
        <ContractScheduledRateTagSelect
          value={formData.tag}
          options={tagOptionsText}
          onChange={(tag) => setFormData({ ...formData, tag })}
          onCreate={(tag) => setFormData({ ...formData, tag })}
          isDisabled={isSaving}
          onClear={() => setFormData({ ...formData, tag: '' })}
        />

        <div className="flex">
          <div className="mr-2 w-1/2">
            <Label className="mt-6">Unit price</Label>
            <div className="relative">
              <Icon className="absolute top-1/2 -translate-y-1/2 ml-3" icon="currency-dollar-circle" variant="light" />
              <Input
                className="pl-10"
                type="number"
                value={formData.amount}
                onChange={({ target }) => setFormData({ ...formData, amount: target.value })}
                isDisabled={isSaving}
                placeholder="--"
                isRequired
              />
            </div>
          </div>
          <div>
            <Label className="mt-6">Unit</Label>
            <Select<MetricUnit>
              className="w-42"
              value={formData.unit}
              options={METRIC_UNIT_OPTIONS}
              onChange={(value) => setFormData({ ...formData, unit: value })}
              isDisabled={isSaving}
              placeholder
              isRequired
            />
          </div>
        </div>

        <Label className="mt-6">Description (optional)</Label>
        <TextArea
          className="h-32"
          value={formData.description}
          placeholder="Enter description"
          onChange={({ target }) => setFormData({ ...formData, description: target.value })}
          isDisabled={isSaving}
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
