import React, { memo, useMemo } from 'react'
import { compact, filter, find, forEach, map } from 'lodash'
import { UserAvatarGroup } from '@cotiss/user'
import { useListMetafield } from '@cotiss/metafield'
import { organisationService } from '@cotiss/organisation'
import { metafieldValueService, useListMetafieldValue } from '@cotiss/metafield-value'
import {
  FilterPreferredSupplierSortKey,
  PreferredSupplierAddDrawer,
  PreferredSupplierListCta,
  PreferredSupplierPopulatedModel,
} from '@cotiss/preferred-supplier'
import {
  Badge,
  Button,
  Icon,
  NoDataPlaceholder,
  PaginationModel,
  ScrollableTable,
  ScrollableTableColumn,
  Text,
  Tooltip,
  datetimeService,
  sortService,
  useCallout,
  useFeature,
} from '@cotiss/common'

type Props = {
  isFiltered: boolean
  isLoading: boolean
  pagination?: PaginationModel
  preferredSuppliers: PreferredSupplierPopulatedModel[]
  onPageChange?: (page: number) => void
  onSort: (field: FilterPreferredSupplierSortKey) => void
  refreshPreferredSuppliers: () => Promise<void>
}

export const PreferredSupplierList = memo((props: Props) => {
  const {
    isFiltered,
    isLoading: isLoadingPreferredSuppliers,
    pagination,
    preferredSuppliers,
    onPageChange,
    onSort,
    refreshPreferredSuppliers,
  } = props
  const { openDrawer } = useCallout()
  const isPreferredSuppliersListViewMetafieldsEnabled = useFeature('preferred-suppliers-list-view-metafields')
  const { metafields, isFetching: isLoadingMetafields } = useListMetafield({
    entityType: 'SUPPLIER_PROFILE',
    isEnabled: isPreferredSuppliersListViewMetafieldsEnabled,
  })
  const { metafieldValues, isFetching: isLoadingMetafieldValues } = useListMetafieldValue({
    resourceIds: compact(map(preferredSuppliers, (preferredSupplier) => preferredSupplier.supplierOrganisation?._id)),
    isEnabled: isPreferredSuppliersListViewMetafieldsEnabled,
  })
  const isLoading = isLoadingPreferredSuppliers || isLoadingMetafields || isLoadingMetafieldValues

  const renderTags = (preferredSupplier: PreferredSupplierPopulatedModel) => {
    if (!preferredSupplier.tags?.length) {
      return <Text size="sm">--</Text>
    }

    const [firstTag, ...rest] = preferredSupplier.tags.sort((a, b) => sortService.sortString(a, b))

    return (
      <>
        <Badge className="mr-1" state="outline" variant="secondary">
          {firstTag}
        </Badge>
        {Boolean(rest.length) && (
          <Tooltip tooltip={rest.join(', ')}>
            <Text className="font-medium" variant="link" size="sm">
              +{rest.length}
            </Text>
          </Tooltip>
        )}
      </>
    )
  }

  const fixedColumns: ScrollableTableColumn[] = useMemo(
    () => [
      {
        heading: 'Name',
        onSort: () => onSort('organisationName'),
        rows: map(preferredSuppliers, (preferredSupplier) => ({
          content: () => (
            <Text className="truncate" font="jakarta" title={preferredSupplier.supplierOrganisation?.name || '--'}>
              {preferredSupplier.supplierOrganisation?.name || '--'}
            </Text>
          ),
          cta: <PreferredSupplierListCta preferredSupplier={preferredSupplier} onSubmitAction={refreshPreferredSuppliers} />,
        })),
      },
    ],
    [preferredSuppliers]
  )

  const columns: ScrollableTableColumn[] = useMemo(() => {
    const columns = [
      {
        heading: 'Organisation status',
        rows: map(preferredSuppliers, ({ supplierOrganisation }) => ({
          content: () =>
            supplierOrganisation?.claimedStatus ? (
              <Badge
                state="outline"
                variant={supplierOrganisation.claimedStatus === 'CLAIMED' ? 'secondary' : 'neutral'}
                title={
                  supplierOrganisation.claimedStatus === 'CLAIMED'
                    ? 'One of the contacts associated with this account has created a Cotiss account'
                    : 'None of the contacts associated with this account have created a Cotiss account'
                }
              >
                {supplierOrganisation.claimedStatus === 'CLAIMED' && <Icon className="mr-1" icon="check-verified-02" size={10} />}
                {organisationService.getClaimedStatusText(supplierOrganisation.claimedStatus)}
              </Badge>
            ) : (
              <Text size="sm" variant="light">
                --
              </Text>
            ),
        })),
      },
      {
        heading: 'Contacts',
        rows: map(preferredSuppliers, ({ supplierOrganisation, contacts }) => {
          const accountUserEmails = compact(supplierOrganisation?.account?.accountUser.map(({ email }) => email))
          const uniqueContacts = filter(contacts, (contact) => !accountUserEmails.includes(contact.email))

          return {
            content: () => (
              <UserAvatarGroup
                users={[
                  ...compact(supplierOrganisation?.account?.accountUser),
                  ...map(uniqueContacts, (contact) => ({ firstname: contact.email, surname: '' })),
                ]}
                size="xs"
              />
            ),
          }
        }),
      },
      {
        heading: 'Tags',
        rows: map(preferredSuppliers, (preferredSupplier) => ({
          content: () => renderTags(preferredSupplier),
        })),
      },
      {
        heading: 'Date created',
        onSort: () => onSort('createdAt'),
        rows: map(preferredSuppliers, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
    ]

    // Add metafields columns
    if (isPreferredSuppliersListViewMetafieldsEnabled) {
      forEach(metafields, (metafield) => {
        columns.push({
          heading: metafield.fieldLabel,
          rows: map(preferredSuppliers, ({ supplierOrganisation }) => ({
            tdClassName: 'max-w-[350px]',
            content: () => {
              const metafieldValue = find(metafieldValues, { metafield: metafield._id, resourceId: supplierOrganisation?._id })
              const processedMetafieldValue = metafieldValueService.renderFieldValue({ metafield, metafieldValue })

              if (metafield.fieldType === 'HYPERLINK' && metafieldValue) {
                return (
                  <Button isExternalLink isTruncated href={processedMetafieldValue} variant="secondary" state="text">
                    {processedMetafieldValue}
                  </Button>
                )
              }

              return (
                <Text className="whitespace-pre-wrap line-clamp-3" size="sm" variant="light" title={processedMetafieldValue}>
                  {processedMetafieldValue}
                </Text>
              )
            },
          })),
        })
      })
    }

    return columns
  }, [preferredSuppliers, metafieldValues, isPreferredSuppliersListViewMetafieldsEnabled, metafields])

  if (!isLoading && !preferredSuppliers.length) {
    return (
      <div>
        <div className="flex items-center justify-center h-60 bg-gray-200 rounded">
          <NoDataPlaceholder
            illustration="dot-list"
            variant="transparent"
            label={isFiltered ? 'No contacts have been found' : "You haven't added any contacts or have invitations pending"}
            ctaSize="xs"
            ctaLabel="+ Add contact"
            onCtaClick={() => openDrawer(<PreferredSupplierAddDrawer onClose={refreshPreferredSuppliers} />)}
          />
        </div>
      </div>
    )
  }

  return <ScrollableTable isLoading={isLoading} fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={onPageChange} />
})
