import CreatableSelect from 'react-select/creatable'
import React, { FormEvent, memo, useState } from 'react'
import { compact, filter, includes, map, some, sortBy, uniq } from 'lodash'
import { UserMultiSelect, useListUser } from '@cotiss/user'
import { useApprovalTemplate } from '@cotiss/approval-template'
import { useApprovalTemplateGroup } from '@cotiss/approval-template-group'
import { GqlApprovalTemplateFieldsFragment, GqlApprovalTemplateGroupFieldsFragment, GqlApprovalTemplateModuleType } from '@gql'
import { mutateCreateApprovalTemplateUsers, mutateDeleteApprovalTemplateUsers } from '@cotiss/approval-template-user'
import {
  Checkbox,
  Form,
  Label,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  sentryService,
  useAsyncEffect,
  useCallout,
  useToast,
  SelectOption,
  Button,
} from '@cotiss/common'

const DEFAULT_APPROVAL_TYPES = ['DFA', 'Legal', 'Financial', 'Commercial'] as const

type FormData = {
  name: string
  userIds: string[]
  isOptional: boolean
}

type Props = {
  approvalTemplateGroupId?: string
  approvalTemplate?: GqlApprovalTemplateFieldsFragment
  module: GqlApprovalTemplateModuleType
  onUpdate?: () => void | Promise<unknown>
}

export const SettingsModulesApprovalTemplateCreateUpdateModal = memo(({ approvalTemplateGroupId, approvalTemplate, module, onUpdate }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { users } = useListUser({ permissions: ['APPROVER'] })
  const { queryApprovalTemplateGroupView } = useApprovalTemplateGroup()
  const [approvalTypeOptions, setApprovalTypeOptions] = useState<SelectOption[]>([])
  const [approvalTemplateGroup, setApprovalTemplateGroup] = useState<GqlApprovalTemplateGroupFieldsFragment>()
  const { queryApprovalTemplateList, mutateCreateApprovalTemplate, mutateUpdateApprovalTemplate } = useApprovalTemplate()
  const [formData, setFormData] = useState<FormData>({
    name: approvalTemplate?.name || '',
    userIds: approvalTemplate ? map(approvalTemplate.approvalTemplateUsers, ({ user }) => user.id) : [],
    isOptional: approvalTemplate?.isOptional || false,
  })

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)

      const { approvalTemplates } = await queryApprovalTemplateList({ filter: { module } })
      const approvalTemplateNames = compact(map(approvalTemplates, 'name'))
      const options = map(sortBy(uniq([...DEFAULT_APPROVAL_TYPES, ...approvalTemplateNames])), (name) => ({ value: name, label: name }))

      approvalTemplateGroupId && setApprovalTemplateGroup(await queryApprovalTemplateGroupView({ approvalTemplateGroupId }))
      setApprovalTypeOptions(options)
      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsLoading(false)
    }
  }, [approvalTemplate])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)

      if (approvalTemplate) {
        // Update approval template users
        const approvalTemplateUserIdsToCreate = filter(
          formData.userIds,
          (userId) => !some(approvalTemplate.approvalTemplateUsers, ({ user }) => user.id === userId)
        )

        const approvalTemplateUserIdsToDelete = map(
          filter(approvalTemplate.approvalTemplateUsers, ({ user }) => !includes(formData.userIds, user.id)),
          (approvalTemplateUser) => approvalTemplateUser.id
        )

        // Run queries in parallel
        await Promise.all([
          // Create new approval template users
          mutateCreateApprovalTemplateUsers({ approvalTemplateId: approvalTemplate.id, userIds: approvalTemplateUserIdsToCreate }),

          // Delete approval template users
          mutateDeleteApprovalTemplateUsers({ approvalTemplateUserIds: approvalTemplateUserIdsToDelete }),

          // Update the approval template
          mutateUpdateApprovalTemplate({ approvalTemplateId: approvalTemplate.id, name: formData.name, isOptional: formData.isOptional }),
        ])
      } else {
        // Create approval template
        const createdApprovalTemplate = await mutateCreateApprovalTemplate({
          approvalTemplateGroupId,
          module: 'contract',
          name: formData.name,
          order: approvalTemplateGroup ? approvalTemplateGroup.approvalTemplates.length + 1 : 1,
          isOptional: formData.isOptional,
        })

        // Create approval template users, can't be done in parallel with the approval template create since we need the approval template id first
        await mutateCreateApprovalTemplateUsers({
          approvalTemplateId: createdApprovalTemplate.id,
          userIds: formData.userIds,
        })
      }

      await Promise.all([approvalTemplateGroupId && queryApprovalTemplateGroupView({ approvalTemplateGroupId }), onUpdate && onUpdate()])

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[550px] max-w-[550px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${approvalTemplate ? 'Edit' : 'Add'} sequence`} isDisabled={isSaving} />
      <ModalContent isScrollable={formData.userIds.length > 3}>
        <Label>Select or create approval type</Label>
        <CreatableSelect
          value={formData.name ? { label: formData.name, value: formData.name } : undefined}
          options={approvalTypeOptions}
          onChange={(approvalType) => setFormData({ ...formData, name: approvalType?.value || '' })}
          placeholder="Search or create approval type..."
          noOptionsMessage={() => 'Type to create approval type...'}
          required
          isDisabled={isLoading}
          isClearable={!isLoading}
          components={{ IndicatorSeparator: null }}
        />
        <Label className="mt-4">Assign users to this approval</Label>
        <UserMultiSelect value={formData.userIds} options={users} onChange={(userIds) => setFormData({ ...formData, userIds })} isRequired />
      </ModalContent>
      <ModalFooter className="flex items-center justify-between" isSaving={isSaving}>
        <label className="flex items-center" htmlFor="approval-type-optional-checkbox">
          <Checkbox
            id="approval-type-optional-checkbox"
            className="mr-1"
            isChecked={formData.isOptional}
            onChange={() => setFormData({ ...formData, isOptional: !formData.isOptional })}
          />
          <Text>This approval type is optional</Text>
        </label>
        <div>
          <Button className="mr-2" onClick={() => closeModal()} state="ghost" variant="link" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="secondary" isLoading={isSaving}>
            Save
          </Button>
        </div>
      </ModalFooter>
    </Form>
  )
})
