import { map } from 'lodash'
import React, { ReactNode, memo, useState } from 'react'
import { Button, Dropdown, DropdownContent, Icon, Tooltip } from '@cotiss/common'

export type TableRowCtaAction = {
  label: ReactNode
  onClick?: () => void
  href?: string
  isOpenNewTab?: boolean
  disabledTooltip?: string
  isDisabled?: boolean
}

type Props = {
  cta?: TableRowCtaAction
  actions?: TableRowCtaAction[]
  isDisabled?: boolean
}

export const TableRowCta = memo(({ cta, actions, isDisabled }: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <div className="flex items-center">
      {Boolean(actions?.length) && (
        <>
          <Button
            className="mr-2"
            onClick={() => setIsDropdownOpen(true)}
            shape="square"
            state="translucent"
            variant="secondary-dark"
            size="xs"
            isDisabled={isDisabled}
            isRounded
          >
            <Icon icon="dots" variant="secondary" />
          </Button>
          <Dropdown className="top-1/2 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
            {map(actions, ({ label, onClick, href, isOpenNewTab, disabledTooltip, isDisabled }, index) => (
              <Tooltip key={index} tooltip={disabledTooltip || ''} isEnabled={Boolean(isDisabled && disabledTooltip)}>
                <DropdownContent
                  className="flex items-center"
                  onClick={onClick}
                  href={href}
                  isOpenNewTab={isOpenNewTab}
                  isLink={Boolean(href)}
                  isDisabled={isDisabled}
                >
                  {label}
                </DropdownContent>
              </Tooltip>
            ))}
          </Dropdown>
        </>
      )}

      {cta && (cta.href || cta.onClick) && (
        <Button
          href={cta.href}
          onClick={cta.onClick}
          state="outline"
          variant="secondary"
          size="xs"
          isDisabled={cta.isDisabled}
          isLink={Boolean(cta.href)}
          isOpenNewTab={cta.isOpenNewTab}
        >
          {cta.label}
        </Button>
      )}
    </div>
  )
})
