import React, { FormEvent, useState, memo, useEffect } from 'react'
import {
  Form,
  ModalHeader,
  ModalContent,
  Label,
  ModalFooter,
  Input,
  useCallout,
  sentryService,
  useToast,
  useAnalytics,
  Select,
  Text,
} from '@cotiss/common'
import { useGetSsoConfiguration, useMutateAuth, SsoConfigurationModel, SsoProviderName } from '@cotiss/auth'
import { USER_PERMISSION_MAP, UserPermission } from '@cotiss/user'
import { map } from 'lodash'

type Props = {
  label: string
  field: FieldName
}

type FormDataValues = {
  loginUrl: string
  metadataUrl: string
  providerName: SsoProviderName
  defaultUserPermission: UserPermission
}

type FieldName = keyof FormDataValues

export const SsoConfigurationEditModal = memo(({ label, field }: Props) => {
  const { configurations } = useGetSsoConfiguration()
  const [isSaving, setIsSaving] = useState(false)
  const [configuration, setConfiguration] = useState<SsoConfigurationModel | null>(null)
  const [errorMessage, setErrorMessage] = useState('')
  const { closeModal } = useCallout()
  const { updateSsoConfiguration } = useMutateAuth()
  const { openToast } = useToast()
  const { track } = useAnalytics()
  const [formData, setFormData] = useState<FormDataValues>({
    loginUrl: '',
    metadataUrl: '',
    providerName: 'okta',
    defaultUserPermission: 'PROCUREMENT_MANAGER',
  })

  useEffect(() => {
    const configuration = configurations?.length ? configurations[0] : null
    if (!configuration) {
      return
    }
    setConfiguration(configuration)
    setFormData({
      loginUrl: configuration.url || '',
      metadataUrl: configuration.metadataUrl || '',
      providerName: configuration.providerName || '',
      defaultUserPermission: configuration.defaultUserPermission ?? USER_PERMISSION_MAP.PROCUREMENT_MANAGER,
    })
  }, [configurations])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!configuration) {
      openToast('Something went wrong. Please refresh and try again.')
      return
    }

    if (field === 'metadataUrl' && !formData.metadataUrl.toLowerCase().includes('https')) {
      setErrorMessage('Metadata URL must use https')
      return
    }

    setIsSaving(true)

    try {
      track('sso_configuration_update_submit')
      await updateSsoConfiguration(configuration.id, {
        ...(field === 'providerName' && formData.providerName && { providerName: formData.providerName }),
        ...(field === 'loginUrl' && formData.loginUrl && { url: formData.loginUrl }),
        ...(field === 'metadataUrl' && formData.metadataUrl && { metadataUrl: formData.metadataUrl }),
        ...(field === 'defaultUserPermission' && formData.defaultUserPermission && { defaultUserPermission: formData.defaultUserPermission }),
      })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(`Couldn't save. Please try again.`, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${configuration ? 'Edit' : 'Add'} ${label.toLowerCase()}`} isDisabled={isSaving} />
      <ModalContent>
        <Label>{label}</Label>
        {field === 'providerName' && (
          <Select<SsoProviderName>
            value={formData.providerName}
            options={[
              { label: 'Okta', value: 'okta' },
              { label: 'Microsoft', value: 'microsoft' },
              { label: 'ForgeRock', value: 'forgerock' },
            ]}
            onChange={(value) => setFormData({ ...formData, providerName: value })}
            placeholder
            isRequired
            isError={Boolean(errorMessage)}
          />
        )}
        {(field === 'loginUrl' || field === 'metadataUrl') && (
          <Input
            autoFocus
            placeholder="Type here"
            value={formData[field] as string}
            isRequired
            onChange={({ target }) => setFormData({ ...formData, [field]: target.value })}
            isError={Boolean(errorMessage)}
          />
        )}
        {field === 'defaultUserPermission' && (
          <Select<UserPermission>
            value={formData.defaultUserPermission}
            options={map(USER_PERMISSION_MAP, (label, value) => ({ label: label, value: value as UserPermission }))}
            onChange={(value) => setFormData({ ...formData, defaultUserPermission: value })}
            placeholder
            isRequired
            isError={Boolean(errorMessage)}
          />
        )}
        {errorMessage && (
          <Text className="mt-1" variant="danger">
            {errorMessage}
          </Text>
        )}
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm isDisabled={false} />
    </Form>
  )
})
