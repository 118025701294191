import { useHistory } from 'react-router-dom'
import React, { FormEvent, memo, useEffect, useState } from 'react'
import { ProjectSelect, useGetProject, useListProject, useMutateProject } from '@cotiss/project'
import { ProcurementListPopulatedModel, ProcurementModel, useMutateProcurement } from '@cotiss/procurement'
import { Form, Input, Label, ModalContent, ModalFooter, ModalHeader, routerService, sentryService, useCallout, useToast } from '@cotiss/common'

type FormData = {
  title: string
  projectId: string
}

type Props = {
  procurement?: ProcurementListPopulatedModel | ProcurementModel
}

export const ProcurementCreateUpdateModal = memo(({ procurement }: Props) => {
  const { push } = useHistory()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [projectTitle, setProjectTitle] = useState('')
  const { createProject, updateProject } = useMutateProject()
  const { projects, isLoading: isProjectsLoading } = useListProject()
  const { createProcurement, updateProcurement } = useMutateProcurement()
  const { project, isLoading: isProjectLoading } = useGetProject(procurement?.project)
  const [formData, setFormData] = useState<FormData>({
    title: procurement?.title || '',
    projectId: procurement?.project || '',
  })

  useEffect(() => {
    if (project) {
      setProjectTitle(project.title)
    }
  }, [project])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      const formDataToSubmit = { ...formData }

      if (!procurement) {
        if (projectTitle) {
          const project = await createProject({ title: projectTitle })
          formDataToSubmit.projectId = project._id
        }

        const createdProcurement = await createProcurement(formDataToSubmit)
        push(routerService.getHref('/procurement/overview/:procurementId/:tab?/:nestedTab?', { procurementId: createdProcurement._id }))
      } else {
        await Promise.all([
          updateProcurement(procurement._id, formDataToSubmit),
          projectTitle && updateProject(formData.projectId, { title: projectTitle }),
        ])
      }
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleCreate = (projectTitle: string) => {
    setFormData({ ...formData, projectId: projectTitle })
    setProjectTitle(projectTitle)
  }

  const handleClear = () => {
    setFormData({ ...formData, projectId: '' })
    setProjectTitle('')
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${procurement ? 'Update' : 'Create'} procurement`} isDisabled={isSaving} />
      <ModalContent>
        <Label>Title</Label>
        <Input
          value={formData.title}
          onChange={({ target }) => setFormData({ ...formData, title: target.value })}
          placeholder="Title"
          maxLength={100}
          isDisabled={isSaving}
          isRequired
        />
        <Label className="mt-4">Project</Label>
        {project ? (
          <Input
            value={projectTitle}
            onChange={({ target }) => setProjectTitle(target.value)}
            placeholder="Project"
            maxLength={100}
            isDisabled={(procurement && isProjectLoading) || isProjectsLoading || isSaving}
            isRequired
          />
        ) : (
          <ProjectSelect
            value={formData.projectId}
            options={projects}
            onChange={(projectId) => setFormData({ ...formData, projectId })}
            onCreate={handleCreate}
            onClear={handleClear}
            isDisabled={(procurement && isProjectLoading) || isProjectsLoading || isSaving}
            isRequired
          />
        )}
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
