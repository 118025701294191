import { includes, map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { TenderTypeBadge } from '@cotiss/tender'
import { UserAvatarGroup, useGetLoggedInUser, useUserAccess } from '@cotiss/user'
import { ProcurementResponseListItemCta, useListProcurementResponse } from '@cotiss/procurement-response'
import {
  FourOhThreePage,
  Header,
  NoDataPlaceholder,
  Page,
  PageContent,
  ScrollableTable,
  ScrollableTableColumn,
  Text,
  paginationService,
  sortService,
  useSortTable,
} from '@cotiss/common'

type ResponsesTableSortKey = 'title' | 'reference' | 'organisation' | 'createdAt'

export const ProcurementResponseListPage = memo(() => {
  const { user } = useGetLoggedInUser()
  const { permissions } = useUserAccess()
  const [currentPage, setCurrentPage] = useState(1)
  const { sortKey, sortDirection, onSort } = useSortTable<ResponsesTableSortKey>({ initialKey: 'title' })
  const { procurementResponses, meta, isLoading } = useListProcurementResponse({ organisationId: user?.account.organisation?._id })
  const { processedProcurementResponses, pagination } = useMemo(() => {
    const result = procurementResponses.sort((a, b) => {
      if (sortKey === 'title') {
        return sortService.sortString(a.procurement.title, b.procurement.title)
      } else if (sortKey === 'reference') {
        return sortService.sortString(a.procurement.internalIdentifier, b.procurement.internalIdentifier)
      } else if (sortKey === 'organisation') {
        return sortService.sortString(a.procurement.procuringEntity.name, b.procurement.procuringEntity.name)
      } else if (sortKey === 'createdAt') {
        return sortService.sortDate(a.createdAt, b.createdAt)
      }

      return 0
    })

    const sortedResult = sortDirection === 'asc' ? result : result.reverse()
    const { items: processedProcurementResponses, pagination } = paginationService.paginate(sortedResult, { currentPage })

    return { processedProcurementResponses, pagination }
  }, [procurementResponses, sortKey, sortDirection, currentPage])

  if (!permissions.isSupplier) {
    return <FourOhThreePage />
  }

  const renderContent = () => {
    if (!isLoading && !processedProcurementResponses.length) {
      return (
        <div className="flex items-center justify-center h-[calc(100%-64px)]">
          <NoDataPlaceholder label="You have not responded to any tenders" />
        </div>
      )
    }

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Procurement name',
        onSort: () => onSort('title'),
        rows: map(processedProcurementResponses, (procurementResponse) => ({
          content: () => (
            <Text className="truncate" font="jakarta">
              {procurementResponse.procurement.title}
            </Text>
          ),
          cta: (
            <ProcurementResponseListItemCta
              procurementResponse={procurementResponse}
              isDisabled={!includes(meta?.hasAccess, procurementResponse._id)}
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Reference',
        onSort: () => onSort('reference'),
        rows: map(processedProcurementResponses, ({ procurement }) => ({
          content: () => (
            <Text variant="light" size="sm">
              {procurement.internalIdentifier}
            </Text>
          ),
        })),
      },
      {
        heading: 'Organisation',
        onSort: () => onSort('organisation'),
        rows: map(processedProcurementResponses, ({ procurement }) => ({
          content: () => (
            <Text className="truncate" variant="light" size="sm">
              {procurement.procuringEntity.name}
            </Text>
          ),
        })),
      },
      {
        heading: 'Users',
        rows: map(processedProcurementResponses, ({ procurementResponseUsers }) => ({
          content: () => <UserAvatarGroup users={map(procurementResponseUsers, ({ user }) => user)} size="xs" />,
        })),
      },
      {
        heading: 'Type',
        rows: map(processedProcurementResponses, ({ tenderResponses }) => ({
          content: () => (
            <>
              {map(tenderResponses, ({ tender }) => (
                <TenderTypeBadge key={tender._id} className="mr-1" tenderType={tender.tenderType} />
              ))}
            </>
          ),
        })),
      },
    ]

    return (
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    )
  }

  return (
    <Page>
      <Header>
        <Text className="font-semibold" size="h5" variant="heading" font="jakarta">
          Responses
        </Text>
      </Header>
      <PageContent>{renderContent()}</PageContent>
    </Page>
  )
})
