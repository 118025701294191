import { SelectOption } from '@cotiss/common'
import { ContractPeriod } from '@cotiss/contract'

export const CONTRACT_QUERY_KEYS = ['/v2/contract-shells', '/v2/contract-shells/filter'] as const
export const CONTRACT_ROUTES = [
  '/contract',
  '/contract/list',
  '/contract/list/:tab?',
  '/contract/view/:contractShellId/:tab?',
  '/contract/approval/:contractShellId/:contractId/:approvalId?',
  '/contract/approval/:contractShellId/:contractId/:approvalId?/view/:tab?',
] as const

export const CONTRACT_PERIOD_OPTIONS: SelectOption<ContractPeriod>[] = [
  { value: 'day', label: 'Days' },
  { value: 'week', label: 'Weeks' },
  { value: 'month', label: 'Months' },
  { value: 'year', label: 'Years' },
]
