import classNames from 'classnames'
import React, { memo, ReactNode } from 'react'
import { BaseVariant, Button } from '@cotiss/common'

export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg'
export type AvatarState = 'filled' | 'outline'
export const AVATAR_VARIANTS: BaseVariant[] = ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'neutral']

type Props = {
  className?: string
  children: ReactNode
  size?: AvatarSize
  title?: string
  variant?: BaseVariant
  state?: AvatarState
  onClick?: () => void
}

export const Avatar = memo(({ className, children, title, size = 'md', variant = 'neutral', state = 'filled', onClick }: Props) => {
  let classes = classNames(className, 'flex items-center justify-center shrink-0 rounded-full', {
    'font-medium text-2xs h-6 w-6': size === 'xs',
    'font-semibold text-xs h-8 w-8': size === 'sm',
    'font-semibold text-sm h-10 w-10': size === 'md',
    'font-semibold text-lg h-14 w-14': size === 'lg',
  })

  if (state === 'filled') {
    classes = classNames(classes, {
      'bg-primary-200 text-primary-500': variant === 'primary',
      'bg-secondary-200 text-secondary-500': variant === 'secondary',
      'bg-green-200 text-green-500': variant === 'success',
      'bg-orange-200 text-orange-500': variant === 'warning',
      'bg-pink-200 text-pink-500': variant === 'danger',
      'bg-blue-200 text-blue-500': variant === 'info',
      'bg-gray-100 text-gray-400': variant === 'neutral',
    })
  }

  if (state === 'outline') {
    classes = classNames(classes, 'bg-primary-50 border', {
      'border-primary-500 text-primary-500': variant === 'primary',
      'border-secondary-500 text-secondary-500': variant === 'secondary',
      'border-green-500 text-green-500': variant === 'success',
      'border-orange-500 text-orange-500': variant === 'warning',
      'border-pink-500 text-pink-500': variant === 'danger',
      'border-blue-500 text-blue-500': variant === 'info',
      'border-gray-500 text-gray-500': variant === 'neutral',
    })
  }

  return onClick ? (
    <Button className={classes} onClick={onClick} state="raw">
      {children}
    </Button>
  ) : (
    <div className={classes} title={title}>
      {children}
    </div>
  )
})
