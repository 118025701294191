import { includes } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { GqlEvaluationCriteriaRatingScale, GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { Button, Drawer, Label, Select, Text, sentryService, useCallout, useToast } from '@cotiss/common'
import {
  EVALUATION_EVENT_RATING_SCALE_OPTIONS,
  IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES,
  EvaluationEventRatingScaleDefinitionList,
  useEvaluationCriteria,
  useEvaluationEnvelope,
  useEvaluationEvent,
  useEvaluationEventAnalytics,
} from '@cotiss/evaluation-event'

type FormData = {
  defaultRatingScale: GqlEvaluationCriteriaRatingScale
}

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
}

export const EvaluationEventEnvelopeRatingScaleDrawer = memo(({ evaluationEnvelope }: Props) => {
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationEvent } = useEvaluationEvent()
  const { queryEvaluationEnvelopeList, mutateUpdateEvaluationEnvelope } = useEvaluationEnvelope()
  const { queryEvaluationCriteriaList } = useEvaluationCriteria()
  const [formData, setFormData] = useState<FormData>({
    defaultRatingScale: evaluationEnvelope.defaultRatingScale,
  })

  useEffect(() => {
    setFormData({
      defaultRatingScale: evaluationEnvelope.defaultRatingScale,
    })
  }, [evaluationEnvelope])

  useEffect(() => {
    track('evaluation_event_wizard_envelope_edit_rating_scale_view')
  }, [])

  const handleSubmit = async () => {
    if (!evaluationEvent) {
      return
    }

    track('evaluation_event_wizard_envelope_edit_rating_scale_submit')

    try {
      setIsSaving(true)
      await mutateUpdateEvaluationEnvelope({
        evaluationEnvelopeId: evaluationEnvelope.id,
        defaultRatingScale: formData.defaultRatingScale,
        isScored: true,
      })

      // Changing the default rating scale will affect the criteria rating scale, so we need to refresh both.
      await Promise.all([
        queryEvaluationEnvelopeList({ filter: { evaluationEventId: evaluationEvent.id } }),
        queryEvaluationCriteriaList({ filter: { evaluationEventId: evaluationEvent.id } }),
      ])
      closeDrawer()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading" font="jakarta">
      Edit default envelope rating scale
    </Text>
  )

  const renderFooter = () => (
    <>
      <Button className="mr-2" type="submit" variant="secondary" isLoading={isSaving}>
        Confirm
      </Button>
      <Button onClick={() => closeDrawer()} state="ghost" variant="link" isDisabled={isSaving}>
        Cancel
      </Button>
    </>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <div className="flex justify-between">
        <Label className="flex-1">Select default rating scale</Label>
        <Select<GqlEvaluationCriteriaRatingScale>
          className="flex-1"
          value={formData.defaultRatingScale}
          options={EVALUATION_EVENT_RATING_SCALE_OPTIONS}
          onChange={(defaultRatingScale) => setFormData({ ...formData, defaultRatingScale })}
          isDisabled={isSaving}
          placeholder
          isRequired
          isFull={false}
        />
      </div>
      {includes(IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES, formData.defaultRatingScale) && (
        <EvaluationEventRatingScaleDefinitionList className="mt-4" ratingScale={formData.defaultRatingScale} />
      )}
    </Drawer>
  )
})
