import { SelectOption } from '@cotiss/common'
import { GqlPerformanceMetricUnit, GqlPerformanceScorecardMetricFrequency } from '@gql'

export const PERFORMANCE_ROUTES = [
  '/performance',
  '/performance/scorecard',
  '/performance/scorecard/list/:tab?',
  '/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?',
  '/performance/scorecard/view/:performanceScorecardId/metric/:performanceScorecardMetricId',
  '/performance/scorecard/view/:performanceScorecardId/metric/:performanceScorecardMetricId/cycle/:performanceScorecardMetricCycleId',
] as const

export const PERFORMANCE_METRIC_UNIT_OPTIONS: SelectOption<GqlPerformanceMetricUnit>[] = [
  {
    label: 'Integer',
    value: 'integer',
  },
  {
    label: 'Decimal',
    value: 'decimal',
  },
  {
    label: 'Percentage',
    value: 'percentage',
  },
  {
    label: 'Currency',
    value: 'currency',
  },
]

export const PERFORMANCE_METRIC_FREQUENCY_OPTIONS: SelectOption<GqlPerformanceScorecardMetricFrequency>[] = [
  {
    label: 'Annually',
    value: 'annually',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
]
