import React, { memo, useMemo, useState } from 'react'
import { usePerformanceScorecardMetric, usePerformanceScorecardMetricCycleScore, usePerformanceScorecardUser } from '@cotiss/performance'
import {
  Button,
  ConfirmModal,
  Drawer,
  Hr,
  Icon,
  Input,
  Label,
  sentryService,
  Text,
  TextArea,
  useAsyncEffect,
  useCallout,
  useToast,
} from '@cotiss/common'

type PerformanceScorecardMetricCycleScoreFormData = {
  value: string
  comment: string
}
type Props = {
  performanceScorecardMetricCycleId: string
  performanceScorecardUserId: string
  performanceScorecardMetricCycleScoreId?: string
}

export const PerformanceScorecardMetricCycleEvaluatorScoreDrawer = memo((props: Props) => {
  const { performanceScorecardMetricCycleId, performanceScorecardUserId, performanceScorecardMetricCycleScoreId } = props
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { openModal, closeNarrowDrawer } = useCallout()
  const { performanceScorecardMetric } = usePerformanceScorecardMetric()
  const { performanceScorecardUserInSession } = usePerformanceScorecardUser()
  const {
    performanceScorecardMetricCycleScore,
    queryPerformanceScorecardMetricCycleScoreView,
    mutateCreatePerformanceScorecardMetricCycleScore,
    mutateUpdatePerformanceScorecardMetricCycleScore,
  } = usePerformanceScorecardMetricCycleScore()
  const [formData, setFormData] = useState<PerformanceScorecardMetricCycleScoreFormData>({
    value: '',
    comment: '',
  })

  useAsyncEffect(async () => {
    try {
      if (performanceScorecardMetricCycleScoreId) {
        const performanceScorecardMetricCycleScore = await queryPerformanceScorecardMetricCycleScoreView({ performanceScorecardMetricCycleScoreId })

        setFormData({
          value: performanceScorecardMetricCycleScore.value?.toString() || '',
          comment: performanceScorecardMetricCycleScore.comment || '',
        })
      } else if (performanceScorecardUserInSession?.id === performanceScorecardUserId) {
        const performanceScorecardMetricCycleScore = await mutateCreatePerformanceScorecardMetricCycleScore({
          performanceScorecardMetricCycleId,
          performanceScorecardUserId,
          status: 'inProgress',
        })

        setFormData({
          value: performanceScorecardMetricCycleScore.value?.toString() || '',
          comment: performanceScorecardMetricCycleScore.comment || '',
        })
      }

      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      closeNarrowDrawer()
      setIsSaving(false)
    }
  }, [])

  const { canEdit, isEditing } = useMemo(() => {
    const canEdit = performanceScorecardUserInSession?.id === performanceScorecardUserId
    const isEditing = performanceScorecardMetricCycleScore?.status === 'inProgress' && canEdit

    return { canEdit, isEditing }
  }, [performanceScorecardMetricCycleScore, performanceScorecardUserInSession, performanceScorecardUserId])

  const handleSubmit = async () => {
    if (!performanceScorecardMetricCycleScore) {
      return
    }

    setIsSaving(true)

    try {
      await mutateUpdatePerformanceScorecardMetricCycleScore({
        performanceScorecardMetricCycleScoreId: performanceScorecardMetricCycleScore.id,
        ...formData,
        value: Number(formData.value),
        submittedAt: new Date(),
        status: 'complete',
      })
      closeNarrowDrawer()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading" font="jakarta">
      {canEdit ? 'Update' : 'View'} score
    </Text>
  )

  const renderFooter = () => {
    if (!performanceScorecardMetricCycleScore || !canEdit) {
      return
    }

    if (!isEditing) {
      return (
        <div className="flex items-center">
          <Button
            className="mr-2"
            onClick={() =>
              openModal(
                <ConfirmModal
                  heading="Edit score"
                  description="Are you sure you want to edit this score?"
                  onSubmit={() =>
                    mutateUpdatePerformanceScorecardMetricCycleScore({
                      performanceScorecardMetricCycleScoreId: performanceScorecardMetricCycleScore.id,
                      status: 'inProgress',
                    })
                  }
                />
              )
            }
            state="translucent"
            variant="secondary"
          >
            <Icon className="mr-1" icon="edit-05" />
            Edit score
          </Button>

          <Icon className="mr-1" icon="check-circle" variant="success" />
          <Text className="font-semibold mr-1">Marked as complete</Text>
        </div>
      )
    }

    return (
      <Button type="submit" variant="secondary" isLoading={isSaving}>
        Mark as complete
      </Button>
    )
  }

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit} isNarrow>
      <Label>Value</Label>
      {isEditing && !isLoading && (
        <>
          <Input
            value={formData.value}
            onChange={({ target }) => setFormData({ ...formData, value: target.value })}
            placeholder="Enter score..."
            type="number"
            isDisabled={isSaving}
            isRequired
          />
          <div className="mt-1">
            <Text className="mr-1" size="sm" variant="light" isInline>
              Target:
            </Text>
            <Text size="sm" isInline>
              {performanceScorecardMetric?.target}
            </Text>
          </div>
        </>
      )}
      {!isEditing && !isLoading && <Text>{formData?.value || '--'}</Text>}
      <Hr className="my-4" />
      <Label>Comment</Label>
      {isEditing && !isLoading && (
        <TextArea
          value={formData.comment}
          onChange={({ target }) => setFormData({ ...formData, comment: target.value })}
          placeholder="Enter any notes outlining the justification for the metric value..."
          rows={5}
          isDisabled={isSaving}
          isRequired
        />
      )}
      {!isEditing && !isLoading && <Text className="whitespace-pre-wrap">{formData?.comment || '--'}</Text>}
    </Drawer>
  )
})
