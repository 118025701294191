import classNames from 'classnames'
import React, { memo } from 'react'
import { AvatarSize, Skeleton, SkeletonVariant } from '@cotiss/common'

type Props = {
  className?: string
  size?: AvatarSize
  variant?: SkeletonVariant
  isLoading?: boolean
}

export const AvatarSkeleton = memo(({ className, size = 'md', variant, isLoading }: Props) => {
  const classes = classNames(className, 'flex items-center justify-center shrink-0 rounded-full', {
    'h-6 w-6': size === 'xs',
    'h-8 w-8': size === 'sm',
    'h-10 w-10': size === 'md',
    'h-14 w-14': size === 'lg',
  })

  return <Skeleton className={classes} variant={variant} isLoading={isLoading} isRounded />
})
